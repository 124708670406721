import AppsIcon from '@mui/icons-material/Apps'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavBar } from './NavBar'
import User from './User'
import { isClientUser } from '../../services/keycloak.service'
import { TransparentIconButton } from '../common/TransparentIconButton'
import { useHistory, useParams } from 'react-router-dom'
import { Pages } from '../../router/router'
import { Badge } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import { useCurrentPage } from '../../hooks/useCurrentPage'
import PublishIcon from '@mui/icons-material/Publish'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { openDialog } from '../../redux/actions/dialogActions'
import { useDispatch } from 'react-redux'
import { FILE_IMPORT_DIALOG_ID } from '../../constants/dialogConstants'
import { OrdersByStateCount } from '../../redux/reducers/orders'
import { useSelector } from '../../redux/hooks/useSelector'
import ClientCard from './ClientCard'
import { ClientInformationDialog } from '../dialogs/ClientInformationDialog'

interface Props {
  pageName: string
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(4),
  },
  leftBar: {
    height: '100%',
    width: '300px',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
    margin: '10',
  },
  right: {
    flexGrow: 1,
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '300px',
  },
  child: {
    marginLeft: '300px',
    marginTop: 30,
    paddingRight: theme.spacing(3),
  },
  pageName: {
    fontWeight: 500,
    fontSize: '1rem',
    margin: 0,
  },
  rightTop: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}))

export const NewLayout: React.FC<Props> = ({ children, pageName }) => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const curr = useCurrentPage()
  const { exportOrders } = useInstrumentApi()
  const dispatch = useDispatch()
  const [ordersByStateCount, setOrdersByStateCount] = useState([] as OrdersByStateCount[])
  const { clientId } = useParams<{ clientId: string }>()

  const orderSse = useSelector(state => state.orders.orderSse)

  useEffect(() => {
    if (curr === Pages.Home && orderSse && orderSse.userStates && orderSse.userStates.length > 0) {
      setOrdersByStateCount(orderSse.userStates[0].states)
    }
  })

  const ribon = process.env.REACT_APP_ENV_RIBBON
  return (
    <div className={classes.wrapper}>
      <div className={classes.leftBar}>
        <NavBar />
      </div>
      <div className={classes.right}>
        <div className={classes.topBar}>
          <span>
            {!isClientUser() && clientId && <ClientCard />}
            <p className={classes.pageName}>{t(pageName)}</p>
          </span>
          {ribon && (
            <p className={classes.pageName} style={{ color: '#ff0000' }}>
              {ribon}
            </p>
          )}
          <User />
        </div>
        <div className={classes.child}>{children}</div>
      </div>
      <ClientInformationDialog />
    </div>
  )
}
