import { Redirect, Route, Switch } from 'react-router-dom'
import { ClientList } from '../containers/ClientList'
import { ClientDetail } from '../containers/ClientDetail'
import { InvestmentQuestionnaire } from '../containers/InvestmentQuestionnaire'
import { OrderList } from '../containers/OrderList'
import { useKeycloak } from '@react-keycloak/web'
import { PrivateRoute } from './routes/PrivateRoute'
import LoginPage from '../containers/LoginPage'
import { ClientOrderList } from '../containers/ClientOrderList'
import { InstrumentList } from '../containers/InstrumentList'
import { AccessForbidden } from '../containers/AccessForbidden'
import { getClientIdFromUser, isClientUser } from '../services/keycloak.service'

export enum Pages {
  Home = '/',
  Login = '/login',
  ClientDetail = '/client-detail',
  OrderList = '/order-list',
  ClientOrderList = '/client-order-list',
  ClientInstrumentOrderList = '/client-instrument-order-list',
  InstrumentList = '/instrument-list',
  AccessForbidden = '/access-forbidden',
  InvestmentQuestionnaire = '/investment-questionnaire',
}

const redirectToClientIQ = () => {
  const clientId = getClientIdFromUser()
  return <Redirect to={Pages.InvestmentQuestionnaire + '/' + clientId} />
}

export const AppRouter = () => {
  const { initialized } = useKeycloak()

  if (!initialized) {
    return <div>Loading...</div>
  }

  return (
    <Switch>
      {!isClientUser() && (
        <>
          <PrivateRoute
            path={Pages.ClientDetail + '/:clientId/:portfolio'}
            protectedRoute={false}
            component={ClientDetail}
            exact={true}
          />
          <PrivateRoute
            path={Pages.ClientDetail + '/:clientId/'}
            protectedRoute={false}
            component={ClientDetail}
            exact={true}
          />
          <PrivateRoute
            path={Pages.ClientOrderList + '/:clientId'}
            protectedRoute={false}
            component={ClientOrderList}
            exact={true}
          />
          <PrivateRoute
            path={Pages.InvestmentQuestionnaire + '/:clientId'}
            protectedRoute={false}
            component={InvestmentQuestionnaire}
            exact={true}
          />
          <PrivateRoute
            path={Pages.ClientInstrumentOrderList + '/:clientId/:instrumentName'}
            protectedRoute={false}
            component={ClientOrderList}
            exact={true}
          />
          <PrivateRoute path={Pages.OrderList} protectedRoute={true} component={OrderList} exact={true} />
          <PrivateRoute path={Pages.InstrumentList} protectedRoute={true} component={InstrumentList} exact={true} />
          <Route path={Pages.AccessForbidden} component={AccessForbidden} exact={true} />
          <Route path={Pages.Login} component={LoginPage} exact={true} />
          <PrivateRoute path={Pages.Home} protectedRoute={true} component={ClientList} exact={true} />
        </>
      )}
      {isClientUser() && (
        <>
          <PrivateRoute
            path={Pages.ClientDetail + '/:clientId'}
            protectedRoute={false}
            component={ClientDetail}
            exact={true}
          />
          <PrivateRoute
            path={Pages.ClientDetail + '/:clientId/:portfolioId'}
            protectedRoute={false}
            component={ClientDetail}
            exact={true}
          />
          {/* <PrivateRoute path={Pages.ClientOrderList + '/:clientId'} protectedRoute={false} component={ClientOrderList} exact={true} /> */}
          <PrivateRoute
            path={Pages.InvestmentQuestionnaire + '/:clientId'}
            protectedRoute={false}
            component={InvestmentQuestionnaire}
            exact={true}
          />
          <PrivateRoute path={Pages.Home} protectedRoute={false} component={redirectToClientIQ} exact={true} />
        </>
      )}
    </Switch>
  )
}
