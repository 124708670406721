import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../redux/actions/dialogActions'
import { INVESTMENT_DIALOG_ID } from '../../constants/dialogConstants'
import { makeStyles } from '@mui/styles'
import { MessageAlert } from './MessageAlert'
import graph from './graf01.png'
import { codeListCodeAndDescriptionItems } from '../../utils/codeListToArrowSelect'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { CLIENT_IQ } from '../../constants/codeLists'
import { useInvestmentApi } from '../../hooks/useInvestmentApi'
import { InvestmentBody, IqValues, IqResult } from '../../redux/reducers/investment'
import { Client } from '../../redux/reducers/clients'
import { newThemeObject } from '../../newTheme'
import { capitalizeFirstLetter, hexToRGBA } from '../../utils/styles'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const useStyles: any = makeStyles(theme => ({
  dialog: {
    padding: theme.spacing(3),
    borderRadius: newThemeObject.roundness.basic,
  },
  clientInformationDialogHeader: {
    width: '100%',
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(1),
    alignItems: 'center',
    display: 'flex',
    borderBottom: `2px solid ${newThemeObject.palette.main.secondary}`,
  },
  skillsWrapper: {
    backgroundColor: hexToRGBA(newThemeObject.palette.main.secondary, 0.2),
    borderRadius: newThemeObject.roundness.basic,
    padding: theme.spacing(1),
  },
  skillsTitle: {
    color: newThemeObject.palette.main.primary,
    fontWeight: 600,
    fontSize: '1rem',
  },
  skillsSubtitle: {
    color: newThemeObject.palette.main.sectext,
  },
  skillsContent: {},
  skillsSubcontent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(3, 1fr)',
  },
  skillsSubtitleWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(1, 1fr)',
  },
  skillsQuestion: {
    padding: theme.spacing(0.5),
    width: '100%',
    pointerEvents: 'auto',
  },
  label: {
    fontSize: '0.7rem',
  },
  question: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: '100%',
    borderRadius: newThemeObject.roundness.basic,
    pointerEvents: 'auto',
  },
  questionTitle: {
    fontWeight: 600,
  },
  questionNote: {
    marginLeft: theme.spacing(4),
  },
  active: {
    backgroundColor: hexToRGBA(newThemeObject.palette.main.secondary, 0.5),
    color: newThemeObject.palette.main.primary,
  },
  orange: {
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderColor: theme.palette.error.main,
    paddingLeft: '10px',
    left: '-10px',
  },
  blackBorder: {
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderColor: 'black',
  },
  actionButtons: {
    borderRadius: newThemeObject.roundness.small,
    padding: '5px 20px',
  },
  paddingLeft: {
    paddingLeft: '10px',
  },
  backButton: {
    color: '#000',
  },
  circleWrapper: {
    position: 'absolute',
    display: 'flex',
    gap: theme.spacing(0.5),
    left: '50%',
    bottom: theme.spacing(1.7),
    zIndex: 1,
  },
  circle: {
    width: 7,
    height: 7,
    borderRadius: '50%',
    backgroundColor: newThemeObject.palette.main.secondary,
  },
  activeCircle: {
    backgroundColor: newThemeObject.palette.main.primary,
  },
  formFooter: {
    width: '100%',
    height: theme.spacing(6),
    display: 'block',
    alignContent: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    '& .fadeout': {
      position: 'absolute',
      top: theme.spacing(-12.5),
      left: 0,
      width: '100%',
      height: theme.spacing(13),
      background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
      transition: 'opacity 0.3s ease',
      opacity: 1,
      PointerEvents: 'none',
      zIndex: 0,
    },
    '& .fadeout.hide': {
      opacity: 0,
    },
  },
  buttons: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  textInput: {
    marginLeft: theme.spacing(2),
  },
  textInput2: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  resultsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(7, 1fr)',
    alignItems: 'center',
  },
  resultName: {
    display: 'flex',
    gap: theme.spacing(5),
    alignItems: 'center',
  },
  resultNumber: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  iqtwo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}))

interface Props {
  client: Client
  handleCloseDialog(): any
  existState: any
}

export const InvestmentDialog: React.FC<Props> = ({ client, existState, handleCloseDialog }) => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const { saveInvestment } = useInvestmentApi()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [showFadeout, setShowFadeout] = useState(false)

  const foundDialog = dialogs.find(dialog => dialog.id === 'INVESTMENT_DIALOG_ID')
  const open = !!foundDialog
  // const open = true //TODO: VYMAZAT

  const headerText = [
    'Úvodní informace',
    'Znalosti',
    'Zkušenosti',
    'Finanční zázemí',
    'Invidiuální cíle + ESG',
    'AML - Kontrola zákazníka',
    'Vyhodnocení',
    'Odeslání dotazníku',
  ]

  const newState: IqValues = {
    IQ_1_1_valid: true,
    IQ_1_1_A: false,
    IQ_1_1_B: false,
    IQ_1_1_C: false,
    IQ_1_1_D: false,

    IQ_1_2_valid: true,
    IQ_1_2_A: false,
    IQ_1_2_B: false,
    IQ_1_2_C: false,
    IQ_1_2_D: false,

    IQ_1_3_valid: true,
    IQ_1_3_A: false,
    IQ_1_3_B: false,
    IQ_1_3_C: false,
    IQ_1_3_D: false,

    IQ_1_4_valid: true,
    IQ_1_4_A: false,
    IQ_1_4_B: false,
    IQ_1_4_C: false,
    IQ_1_4_D: false,

    IQ_1_5_valid: true,
    IQ_1_5_A: false,
    IQ_1_5_B: false,
    IQ_1_5_C: false,
    IQ_1_5_D: false,

    IQ_1_6_valid: true,
    IQ_1_6_A: false,
    IQ_1_6_B: false,
    IQ_1_6_C: false,
    IQ_1_6_D: false,

    IQ_1_7_valid: true,
    IQ_1_7_A: false,
    IQ_1_7_B: false,
    IQ_1_7_C: false,
    IQ_1_7_D: false,

    IQ_1_8_valid: true,
    IQ_1_8_A: false,
    IQ_1_8_B: false,
    IQ_1_8_C: false,
    IQ_1_8_D: false,

    IQ_1_9_valid: true,
    IQ_1_9_A: false,
    IQ_1_9_B: false,
    IQ_1_9_C: false,
    IQ_1_9_D: false,

    IQ_1_10_valid: true,
    IQ_1_10_A: false,
    IQ_1_10_B: false,
    IQ_1_10_C: false,
    IQ_1_10_D: false,

    IQ_1_11_valid: true,
    IQ_1_11_A: false,
    IQ_1_11_B: false,
    IQ_1_11_C: false,
    IQ_1_11_D: false,

    IQ_1_12_valid: true,
    IQ_1_12_A: false,
    IQ_1_12_B: false,
    IQ_1_12_B_input: '',
    IQ_1_12_C: false,
    IQ_1_12_C_input: '',
    IQ_1_12_D: false,

    IQ_2_1_valid: true,
    IQ_2_1_A: false,
    IQ_2_2_valid: true,
    IQ_2_2_A: false,
    IQ_2_2_B: false,
    IQ_2_2_C: false,
    IQ_2_3_valid: true,
    IQ_2_3_A: false,
    IQ_2_3_B: false,
    IQ_2_3_C: false,
    IQ_2_4_valid: true,
    IQ_2_4_A: false,
    IQ_2_4_B: false,
    IQ_2_4_C: false,
    IQ_2_5_valid: true,
    IQ_2_5_A: false,
    IQ_2_5_B: false,
    IQ_2_5_C: false,
    IQ_2_6_valid: true,
    IQ_2_6_A: false,
    IQ_2_6_B: false,
    IQ_2_6_C: false,
    IQ_2_7_valid: true,
    IQ_2_7_A: false,
    IQ_2_7_B: false,
    IQ_2_7_C: false,
    IQ_2_8_valid: true,
    IQ_2_8_A: false,
    IQ_2_8_B: false,
    IQ_2_8_C: false,
    IQ_2_9_valid: true,
    IQ_2_9_A: false,
    IQ_2_9_B: false,
    IQ_2_9_C: false,
    IQ_2_10_valid: true,
    IQ_2_10_A: false,
    IQ_2_10_B: false,
    IQ_2_10_C: false,
    IQ_2_11_valid: true,
    IQ_2_11_A: false,
    IQ_2_11_B: false,
    IQ_2_11_C: false,

    IQ_2_12_valid: true,
    IQ_2_12_A: false,
    IQ_2_12_B: false,
    IQ_2_12_C: false,
    IQ_2_13_valid: true,
    IQ_2_13_A: false,
    IQ_2_13_B: false,
    IQ_2_13_C: false,
    IQ_2_14_valid: true,
    IQ_2_14_A: false,
    IQ_2_14_B: false,
    IQ_2_14_C: false,
    IQ_2_15_valid: true,
    IQ_2_15_A: false,
    IQ_2_15_B: false,
    IQ_2_15_C: false,
    IQ_2_16_valid: true,
    IQ_2_16_A: false,
    IQ_2_16_B: false,
    IQ_2_16_C: false,
    IQ_2_17_valid: true,
    IQ_2_17_A: false,
    IQ_2_17_B: false,
    IQ_2_17_C: false,
    IQ_2_18_valid: true,
    IQ_2_18_A: false,
    IQ_2_18_B: false,
    IQ_2_18_C: false,
    IQ_2_19_valid: true,
    IQ_2_19_A: false,
    IQ_2_19_B: false,
    IQ_2_19_C: false,

    IQ_3_1_valid: true,
    IQ_3_1_A: false,
    IQ_3_1_B: false,
    IQ_3_1_C: false,
    IQ_3_1_D: false,

    IQ_3_2_valid: true,
    IQ_3_2_A: false,
    IQ_3_2_B: false,
    IQ_3_2_C: false,
    IQ_3_2_D: false,
    IQ_3_2_E: false,

    IQ_3_3_valid: true,
    IQ_3_3_A: false,
    IQ_3_3_B: false,
    IQ_3_3_C: false,
    IQ_3_3_D: false,

    IQ_3_4_valid: true,
    IQ_3_4_A: false,
    IQ_3_4_B: false,
    IQ_3_4_C: false,
    IQ_3_4_D: false,
    IQ_3_4_E: false,
    IQ_3_4_E_input: '',

    IQ_3_5_valid: true,
    IQ_3_5_A: false,
    IQ_3_5_B: false,
    IQ_3_5_C: false,
    IQ_3_5_D: false,
    IQ_3_5_E: false,

    IQ_4_1_valid: true,
    IQ_4_1_A: false,
    IQ_4_1_B: false,

    IQ_4_2_valid: true,
    IQ_4_2_A: false,
    IQ_4_2_B: false,
    IQ_4_2_C: false,
    IQ_4_2_D: false,

    IQ_4_3_valid: true,
    IQ_4_3_A: false,
    IQ_4_3_B: false,
    IQ_4_3_C: false,
    IQ_4_3_D: false,
    IQ_4_3_E: false,

    IQ_4_4_valid: true,
    IQ_4_4_A: false,
    IQ_4_4_B: false,
    IQ_4_4_C: false,
    IQ_4_4_D: false,

    IQ_4_5_valid: true,
    IQ_4_5_A: false,
    IQ_4_5_B: false,
    
    IQ_6_1_valid: true,
    IQ_6_1_A: false,
    IQ_6_1_B: false,
    IQ_6_1_C: false,
    IQ_6_1_D: false,
    IQ_6_1_D_input: "",

    IQ_6_2_valid: true,
    IQ_6_2_A: false,
    IQ_6_2_B: false,
    IQ_6_2_C: false,
    IQ_6_2_D: false,

    IQ_6_3_valid: true,
    IQ_6_3_A: false,
    IQ_6_3_B: false,
    IQ_6_3_C: false,
    IQ_6_3_D: false,
    IQ_6_3_D_input: "",

    IQ_6_4_valid: true,
    IQ_6_4_A: false,
    IQ_6_4_B: false,
    IQ_6_4_C: false,
    IQ_6_4_C_input: "",

    IQ_6_5_valid: true,
    IQ_6_5_A: false,
    IQ_6_5_A_input1: "",
    IQ_6_5_A_input2: "",
    IQ_6_5_A_input3: "",
    IQ_6_5_A_input4: "",
    IQ_6_5_A_input5: "",
    IQ_6_5_B: false,
    IQ_6_5_C: false,
    IQ_6_5_D: false,
    IQ_6_5_D_input: "",

    IQ_6_6_A_input1: "",
    IQ_6_6_A_input2: "",
    IQ_6_6_A_input3: "",
    IQ_6_6_A_input4: "",
  }

  const newInvestmentResult: IqResult = {
    category: '',
    skills: '',
    horizonte: '',
    investmentGoal: '',
    lossAbility: '',
    risk: '',
    esg: '',
    total: '',

    totalPoint: 0,
    skillsPoint: 0,
    experiencesPoint: 0,
    financialGoals: 0,
    financialGround: 0,
  }

  const emptyQi: any[] = []

  const [step, setStep] = useState({ step: 0 })
  const [values, setValues] = useState(existState ? existState : newState)
  const [skillsDisabled01, setSkillsDisabled01] = useState(false)
  const [skillsDisabled02, setSkillsDisabled02] = useState(false)
  const [message, setMessage] = useState('')
  const [messageOpen, setMessageOpen] = useState(false)
  const { codeList, getCodeList } = useInstrumentApi()
  const [qi, setQi] = useState(emptyQi)
  const [investmentResult, setInvestmentResult] = useState(newInvestmentResult)

  const ti = (item: string) => {
    for (let i = 0; i < qi.length; i++) {
      if (qi[i].code === item) {
        return qi[i].description
      }
    }
  }

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(INVESTMENT_DIALOG_ID))
    }
  }, [])

  useEffect(() => {
    getCodeList()
  }, [])

  useEffect(() => {
    setQi(codeListCodeAndDescriptionItems(CLIENT_IQ, codeList))
  }, [codeList])

  useEffect(() => {
    if (existState) {
      setValues(existState)
      if (existState.IQ_2_1_A === false) {
        setSkillsDisabled01(true)
      } else {
        setSkillsDisabled02(true)
      }
    }
  }, [existState])

  const handleClose = () => {
    dispatch(closeDialog(INVESTMENT_DIALOG_ID))
  }

  const handleSave = () => {
    setLoading(true)
    saveInvestment(client.clientId, {
      values: values,
      investmentResult: investmentResult,
    }).then(() => {
      setLoading(false)
      handleCloseDialog()
      handleClose()
    })
  }

  const handleLeftButton = () => {
    if (step.step === 0) {
      handleClose()
    } else if (step.step > 0) {
      setStep({ step: step.step - 1 })
    } else {
      setStep({ step: 0 })
    }
  }

  const handleRightButton = () => {
    if (step.step === 6) {
      handleSave()
    } else if (step.step >= 0) {
      if (validationIQ()) {
        if (step.step === 4) {
          let horizonteTmp = ''
          if (values.IQ_4_2_A === true) horizonteTmp = 'IQ_5_3_b'
          else if (values.IQ_4_2_B === true) horizonteTmp = 'IQ_5_3_c'
          else if (values.IQ_4_2_C === true) horizonteTmp = 'IQ_5_3_d'
          else if (values.IQ_4_2_D === true) horizonteTmp = 'IQ_5_3_e'

          let riskTmp = ''
          if (values.IQ_4_3_A === true) riskTmp = 'IQ_5_6_b'
          else if (values.IQ_4_3_B === true) riskTmp = 'IQ_5_6_c'
          else if (values.IQ_4_3_C === true) riskTmp = 'IQ_5_6_d'
          else if (values.IQ_4_3_D === true) riskTmp = 'IQ_5_6_e'
          else if (values.IQ_4_3_E === true) riskTmp = 'IQ_5_6_f'

          setInvestmentResult({
            category: 'IQ_5_1_b',
            skills: getSkillsType(),
            horizonte: horizonteTmp,
            investmentGoal: values.IQ_4_1_A === true ? 'IQ_5_4_b' : values.IQ_4_1_B === true ? 'IQ_5_4_c' : '',
            // lossAbility: (values.IQ_4_3_A || values.IQ_4_4_A || values.IQ_4_4_B ) ? 'IQ_5_5_c' : 'IQ_5_5_b',
            lossAbility: values.IQ_4_3_A || values.IQ_4_4_A ? 'IQ_5_5_c' : 'IQ_5_5_b',
            risk: riskTmp,
            esg: values.IQ_4_5_A === true ? 'IQ_5_7_b' : values.IQ_4_5_B === true ? 'IQ_5_7_c' : '',
            total: getSuitability(),

            totalPoint: getTotal(),
            skillsPoint: getSkills(),
            experiencesPoint: getExperiences(),
            financialGoals: getFinancialGoals(),
            financialGround: getFinancialGround(),
          })
        }
        setStep({ step: step.step + 1 })
      } else {
        setMessageOpen(true)
      }
    } else {
      setStep({ step: 6 })
    }
  }

  const handleCheckBox = (event: any) => {
    groupeCheck(event.target.id, event.target.checked)
    if (
      event.target.id === 'IQ_1_12_B_input'
      || event.target.id === 'IQ_1_12_C_input'
      || event.target.id === 'IQ_3_4_E_input'
      || event.target.id === 'IQ_6_1_D_input'
      || event.target.id === 'IQ_6_3_D_input'
      || event.target.id === 'IQ_6_4_C_input'
      || event.target.id === 'IQ_6_5_D_input'

      || event.target.id === 'IQ_6_5_A_input1'
      || event.target.id === 'IQ_6_5_A_input2'
      || event.target.id === 'IQ_6_5_A_input3'
      || event.target.id === 'IQ_6_5_A_input4'
      || event.target.id === 'IQ_6_5_A_input5'

      || event.target.id === 'IQ_6_6_A_input1'
      || event.target.id === 'IQ_6_6_A_input2'
      || event.target.id === 'IQ_6_6_A_input3'
      || event.target.id === 'IQ_6_6_A_input4'
    ) {
      setValues({ ...values, [event.target.id]: event.target.value })
    } else if (event.target.id === 'IQ_2_1_A') {
      setSkillsDisabled02(event.target.checked)
    } else if (event.target.id.indexOf('Q_2') > 0 && event.target.id.indexOf('Q_2_1_') < 0) {
      // pokud uz neni zadna zasrtnuta
      let check = true
      type ObjectKey = keyof typeof values
      for (let i = 2; i < 20; i++) {
        let a = ('IQ_2_' + i + '_A') as ObjectKey
        let b = ('IQ_2_' + i + '_B') as ObjectKey
        let c = ('IQ_2_' + i + '_C') as ObjectKey
        if (
          (event.target.id != a && values[a]) ||
          (event.target.id != b && values[b]) ||
          (event.target.id != c && values[c])
        ) {
          check = false
          break
        }
      }
      setSkillsDisabled01(event.target.checked || !check)
    }
  }

  const handleCloseMessage = () => {
    setMessageOpen(false)
    setMessage('')
  }

  const groupeCheck = (id: string, checked: boolean) => {
    let tmp = id.split('_')
    const step = tmp[1]
    const groupe = tmp[2]
    const box = tmp[3]

    if (step == '3' && groupe == '4') {
      setValues({ ...values, ['IQ_' + step + '_' + groupe + '_' + box]: checked })
    } else if (box === 'A') {
      setValues({
        ...values,
        ['IQ_' + step + '_' + groupe + '_' + 'A']: checked,
        ['IQ_' + step + '_' + groupe + '_' + 'B']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'C']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'D']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'E']: false,
      })
    } else if (box === 'B') {
      setValues({
        ...values,
        ['IQ_' + step + '_' + groupe + '_' + 'A']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'B']: checked,
        ['IQ_' + step + '_' + groupe + '_' + 'C']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'D']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'E']: false,
      })
    } else if (box === 'C') {
      setValues({
        ...values,
        ['IQ_' + step + '_' + groupe + '_' + 'A']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'B']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'C']: checked,
        ['IQ_' + step + '_' + groupe + '_' + 'D']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'E']: false,
      })
    } else if (box === 'D') {
      setValues({
        ...values,
        ['IQ_' + step + '_' + groupe + '_' + 'A']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'B']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'C']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'D']: checked,
        ['IQ_' + step + '_' + groupe + '_' + 'E']: false,
      })
    } else if (box === 'E') {
      setValues({
        ...values,
        ['IQ_' + step + '_' + groupe + '_' + 'A']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'B']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'C']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'D']: false,
        ['IQ_' + step + '_' + groupe + '_' + 'E']: checked,
      })
    }
  }

  const validationIQ = () => {
    let valid = true
    let tmpValues = { ...values }

    // vzdy jeden check box z kategorie musi byt vybrat
    if (step.step === 1) {
      type ObjectKey = keyof typeof values
      for (let i = 1; i < 13; i++) {
        let tmpValid = false
        let a = ('IQ_1_' + i + '_A') as ObjectKey
        let b = ('IQ_1_' + i + '_B') as ObjectKey
        let c = ('IQ_1_' + i + '_C') as ObjectKey
        let d = ('IQ_1_' + i + '_D') as ObjectKey

        // if ( i===1 && ( values[b] || values[c] || values[d] ) ) {
        //     tmpValues = {...tmpValues, ['IQ_1_'+i+'_valid']: false};
        //     setValues(tmpValues);
        //     setMessage('Zdůvodu odpovědi na otázku 1. není možné pokračovat ve vyplňování dotazníku.')
        //     return false;
        // }

        if (values[a] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_1_' + i + '_valid']: true }
        } else if (values[b] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_1_' + i + '_valid']: true }
        } else if (values[c] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_1_' + i + '_valid']: true }
        } else if (values[d] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_1_' + i + '_valid']: true }
        }

        // označ sekci červeně
        if (tmpValid === false) {
          valid = false
          tmpValues = { ...tmpValues, ['IQ_1_' + i + '_valid']: false }
        }

        const tmpSkils = getSkills()
        if (tmpSkils < 4) {
          setMessage(
            'Bohužel jste v odpovědích prokázal nižší hladinu znalostí než je přípustné minimul pro investování. Nelze pokračovat.'
          )
          return false
        }
      }
      // povine doplnujici informace v pripade vyberu
      if ((values.IQ_1_12_B && !values.IQ_1_12_B_input) || (values.IQ_1_12_C && !values.IQ_1_12_C_input)) {
        valid = false
      }
    }

    if (step.step === 2) {
      if (!skillsDisabled01 && values.IQ_2_1_A === false) {
        valid = false
        tmpValues = { ...tmpValues, IQ_2_1_valid: false }
      } else {
        tmpValues = { ...tmpValues, IQ_2_1_valid: true }
      }

      if (!skillsDisabled02) {
        type ObjectKey = keyof typeof values

        for (let i = 0; i < 6; i++) {
          let a1 = ('IQ_2_' + (3 * i + 2) + '_A') as ObjectKey
          let b1 = ('IQ_2_' + (3 * i + 2) + '_B') as ObjectKey
          let c1 = ('IQ_2_' + (3 * i + 2) + '_C') as ObjectKey

          let a2 = ('IQ_2_' + (3 * i + 3) + '_A') as ObjectKey
          let b2 = ('IQ_2_' + (3 * i + 3) + '_B') as ObjectKey
          let c2 = ('IQ_2_' + (3 * i + 3) + '_C') as ObjectKey

          let a3 = ('IQ_2_' + (3 * i + 4) + '_A') as ObjectKey
          let b3 = ('IQ_2_' + (3 * i + 4) + '_B') as ObjectKey
          let c3 = ('IQ_2_' + (3 * i + 4) + '_C') as ObjectKey

          if (
            (values[a1] || values[b1] || values[c1]) &&
            ((!values[a2] && !values[b2] && !values[c2]) || (!values[a3] && !values[b3] && !values[c3]))
          ) {
            tmpValues = {
              ...tmpValues,
              ['IQ_2_' + (3 * i + 2) + '_valid']: true,
              ['IQ_2_' + (3 * i + 3) + '_valid']: values[a2] || values[b2] || values[c2],
              ['IQ_2_' + (3 * i + 4) + '_valid']: values[a3] || values[b3] || values[c3],
            }
            valid = false
          } else if (
            (values[a2] || values[b2] || values[c2]) &&
            ((!values[a1] && !values[b1] && !values[c1]) || (!values[a3] && !values[b3] && !values[c3]))
          ) {
            tmpValues = {
              ...tmpValues,
              ['IQ_2_' + (3 * i + 2) + '_valid']: values[a1] || values[b1] || values[c1],
              ['IQ_2_' + (3 * i + 3) + '_valid']: true,
              ['IQ_2_' + (3 * i + 4) + '_valid']: values[a3] || values[b3] || values[c3],
            }
            valid = false
          } else if (
            (values[a3] || values[b3] || values[c3]) &&
            ((!values[a1] && !values[b1] && !values[c1]) || (!values[a2] && !values[b2] && !values[c2]))
          ) {
            tmpValues = {
              ...tmpValues,
              ['IQ_2_' + (3 * i + 2) + '_valid']: values[a1] || values[b1] || values[c1],
              ['IQ_2_' + (3 * i + 3) + '_valid']: values[a2] || values[b2] || values[c2],
              ['IQ_2_' + (3 * i + 4) + '_valid']: true,
            }
            valid = false
          } else {
            tmpValues = {
              ...tmpValues,
              ['IQ_2_' + (3 * i + 2) + '_valid']: true,
              ['IQ_2_' + (3 * i + 3) + '_valid']: true,
              ['IQ_2_' + (3 * i + 4) + '_valid']: true,
            }
          }
        }
      }
    }

    // vzdy jeden check box z kategorie musi byt vybrat
    if (step.step === 3) {
      type ObjectKey = keyof typeof values
      for (let i = 1; i < 6; i++) {
        let tmpValid = false
        let a = ('IQ_3_' + i + '_A') as ObjectKey
        let b = ('IQ_3_' + i + '_B') as ObjectKey
        let c = ('IQ_3_' + i + '_C') as ObjectKey
        let d = ('IQ_3_' + i + '_D') as ObjectKey
        let e = ('IQ_3_' + i + '_E') as ObjectKey
        if (values[a] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_3_' + i + '_valid']: true }
        } else if (values[b] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_3_' + i + '_valid']: true }
        } else if (values[c] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_3_' + i + '_valid']: true }
        } else if (values[d] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_3_' + i + '_valid']: true }
        } else if (values[e] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_3_' + i + '_valid']: true }
        }

        // označ sekci červeně
        if (tmpValid === false) {
          valid = false
          tmpValues = { ...tmpValues, ['IQ_3_' + i + '_valid']: false }
        }
      }
      // povine doplnujici informace v pripade vyberu
      if (values.IQ_3_4_E && !values.IQ_3_4_E_input) {
        valid = false
      }
    }

    // vzdy jeden check box z kategorie musi byt vybrat
    if (step.step === 4) {
      type ObjectKey = keyof typeof values
      for (let i = 1; i < 5; i++) {
        let tmpValid = false
        let a = ('IQ_4_' + i + '_A') as ObjectKey
        let b = ('IQ_4_' + i + '_B') as ObjectKey
        let c = ('IQ_4_' + i + '_C') as ObjectKey
        let d = ('IQ_4_' + i + '_D') as ObjectKey
        let e = ('IQ_4_' + i + '_E') as ObjectKey
        if (values[a] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_4_' + i + '_valid']: true }
        } else if (values[b] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_4_' + i + '_valid']: true }
        } else if (values[c] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_4_' + i + '_valid']: true }
        } else if (values[d] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_4_' + i + '_valid']: true }
        } else if (values[e] === true) {
          tmpValid = true
          tmpValues = { ...tmpValues, ['IQ_4_' + i + '_valid']: true }
        }

        // označ sekci červeně
        if (tmpValid === false) {
          valid = false
          tmpValues = { ...tmpValues, ['IQ_4_' + i + '_valid']: false }
        }
      }
    }

    setValues(tmpValues)

    if (!valid) setMessage('Nejsou vyplňeny všechny sekce, nevyplněné se zvýrazní. Prosím vyplňte všechny sekce.')

    return valid
  }

  const getSkills = () => {
    let result = 0
    result += values.IQ_1_1_A === true ? 1 : 0
    result += values.IQ_1_2_C === true ? 1 : 0
    result += values.IQ_1_3_C === true ? 1 : 0
    result += values.IQ_1_4_C === true ? 1 : 0
    result += values.IQ_1_5_B === true ? 1 : 0
    result += values.IQ_1_6_C === true ? 1 : 0
    result += values.IQ_1_7_A === true ? 1 : 0
    result += values.IQ_1_8_C === true ? 1 : 0
    result += values.IQ_1_9_C === true ? 1 : 0
    result += values.IQ_1_10_A === true ? 1 : 0
    result += values.IQ_1_11_B === true ? 1 : 0

    if (values.IQ_1_12_B === true) {
      result +=
        values.IQ_1_1_A &&
        values.IQ_1_2_C &&
        values.IQ_1_3_C &&
        values.IQ_1_4_C &&
        values.IQ_1_5_B &&
        values.IQ_1_6_C &&
        values.IQ_1_7_A &&
        values.IQ_1_8_C &&
        values.IQ_1_9_C
          ? 5
          : 0
    } else if (values.IQ_1_12_C === true) {
      result +=
        values.IQ_1_1_A &&
        values.IQ_1_2_C &&
        values.IQ_1_3_C &&
        values.IQ_1_4_C &&
        values.IQ_1_5_B &&
        values.IQ_1_6_C &&
        values.IQ_1_7_A &&
        values.IQ_1_8_C &&
        values.IQ_1_9_C
          ? 5
          : 0
    }

    return result
  }

  const getExperiences = () => {
    let result = 0
    result += values.IQ_2_2_B === true ? 1 : 0
    result += values.IQ_2_2_C === true ? 2 : 0
    result += values.IQ_2_3_A === true ? 1 : 0
    result += values.IQ_2_3_B === true ? 3 : 0
    result += values.IQ_2_3_C === true ? 3 : 0
    result += values.IQ_2_4_A === true ? 1 : 0
    result += values.IQ_2_4_B === true ? 3 : 0
    result += values.IQ_2_4_C === true ? 3 : 0

    result += values.IQ_2_5_B === true ? 1 : 0
    result += values.IQ_2_5_C === true ? 2 : 0
    result += values.IQ_2_6_A === true ? 1 : 0
    result += values.IQ_2_6_B === true ? 3 : 0
    result += values.IQ_2_6_C === true ? 3 : 0
    result += values.IQ_2_7_A === true ? 1 : 0
    result += values.IQ_2_7_B === true ? 3 : 0
    result += values.IQ_2_7_C === true ? 3 : 0

    result += values.IQ_2_8_B === true ? 1 : 0
    result += values.IQ_2_8_C === true ? 2 : 0
    result += values.IQ_2_9_A === true ? 1 : 0
    result += values.IQ_2_9_B === true ? 3 : 0
    result += values.IQ_2_9_C === true ? 3 : 0
    result += values.IQ_2_10_A === true ? 1 : 0
    result += values.IQ_2_10_B === true ? 3 : 0
    result += values.IQ_2_10_C === true ? 3 : 0

    result += values.IQ_2_11_B === true ? 1 : 0
    result += values.IQ_2_11_C === true ? 2 : 0
    result += values.IQ_2_12_A === true ? 1 : 0
    result += values.IQ_2_12_B === true ? 3 : 0
    result += values.IQ_2_12_C === true ? 3 : 0
    result += values.IQ_2_13_A === true ? 1 : 0
    result += values.IQ_2_13_B === true ? 3 : 0
    result += values.IQ_2_13_C === true ? 3 : 0

    result += values.IQ_2_14_B === true ? 1 : 0
    result += values.IQ_2_14_C === true ? 2 : 0
    result += values.IQ_2_15_A === true ? 1 : 0
    result += values.IQ_2_15_B === true ? 3 : 0
    result += values.IQ_2_15_C === true ? 3 : 0
    result += values.IQ_2_16_A === true ? 1 : 0
    result += values.IQ_2_16_B === true ? 3 : 0
    result += values.IQ_2_16_C === true ? 3 : 0

    result += values.IQ_2_17_B === true ? 1 : 0
    result += values.IQ_2_17_C === true ? 2 : 0
    result += values.IQ_2_18_A === true ? 1 : 0
    result += values.IQ_2_18_B === true ? 3 : 0
    result += values.IQ_2_18_C === true ? 3 : 0
    result += values.IQ_2_19_A === true ? 1 : 0
    result += values.IQ_2_19_B === true ? 3 : 0
    result += values.IQ_2_19_C === true ? 3 : 0

    return result
  }

  const getFinancialGround = () => {
    let result = 0

    result += values.IQ_3_1_A === true ? 3 : 0
    result += values.IQ_3_1_B === true ? 4 : 0
    result += values.IQ_3_1_C === true ? 4 : 0
    result += values.IQ_3_1_D === true ? 4 : 0
    result += values.IQ_3_2_A === true ? 3 : 0
    result += values.IQ_3_2_B === true ? 2 : 0
    result += values.IQ_3_2_D === true ? -1 : 0
    result += values.IQ_3_2_E === true ? -2 : 0
    result += values.IQ_3_3_A === true ? 1 : 0
    result += values.IQ_3_3_B === true ? 2 : 0
    result += values.IQ_3_3_C === true ? 4 : 0
    result += values.IQ_3_3_D === true ? 5 : 0
    result += values.IQ_3_4_A === true ? 3 : 0
    result += values.IQ_3_4_B === true ? 2 : 0
    result += values.IQ_3_4_C === true ? -1 : 0
    result += values.IQ_3_4_D === true ? 1 : 0
    result += values.IQ_3_5_A === true ? 2 : 0
    result += values.IQ_3_5_B === true ? 1 : 0
    result += values.IQ_3_5_D === true ? -1 : 0
    result += values.IQ_3_5_E === true ? -2 : 0

    return result
  }

  const getFinancialGoals = () => {
    let result = 0

    result += values.IQ_4_1_A === true ? 1 : 0
    result += values.IQ_4_1_B === true ? 3 : 0
    result += values.IQ_4_2_A || values.IQ_4_2_B || values.IQ_4_2_C || values.IQ_4_2_D === true ? 1 : 0
    result += values.IQ_4_3_C === true ? 1 : 0
    result += values.IQ_4_3_D === true ? 2 : 0
    result += values.IQ_4_3_E === true ? 3 : 0
    result += values.IQ_4_4_B === true ? 1 : 0
    result += values.IQ_4_4_C === true ? 1 : 0
    result += values.IQ_4_4_D === true ? 2 : 0

    return result
  }

  const getTotal = () => {
    return getSkills() + getExperiences() + getFinancialGround() + getFinancialGoals()
  }

  const getSuitability = () => {
    if (
      getSkills() >= 11 &&
      getExperiences() >= 12 &&
      getFinancialGround() >= 12 &&
      getFinancialGoals() >= 5 &&
      values.IQ_4_3_A !== true &&
      values.IQ_4_4_A !== true &&
      values.IQ_4_4_B !== true
    )
      return 'IQ_5_9_c'
    if (
      getSkills() >= 8 &&
      getExperiences() >= 3 &&
      getFinancialGround() >= 8 &&
      getFinancialGoals() >= 4 &&
      values.IQ_4_3_A !== true &&
      values.IQ_4_4_A !== true &&
      values.IQ_4_4_B !== true
    )
      return 'IQ_5_9_b'
    if (
      (getSkills() >= 4 && getExperiences() >= 0 && getFinancialGround() >= 0 && getFinancialGoals() >= 0) ||
      values.IQ_4_3_A === true ||
      values.IQ_4_4_A === true ||
      values.IQ_4_4_B === true
    )
      return 'IQ_5_9_a'

    return ''
  }

  const getSkillsType = () => {
    const tmpExperiences = getExperiences()
    const tmpSkils = getSkills()

    if (tmpSkils >= 11 && tmpExperiences >= 12) return 'IQ_5_2_d'
    if (tmpSkils >= 8 && tmpExperiences >= 3) return 'IQ_5_2_c'
    if (tmpSkils >= 4 && tmpExperiences >= 0) return 'IQ_5_2_b'

    return ''
  }
  const iq_12 = (
    <>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <Typography variant="inherit">1. {ti('IQ_1_1')}</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_1_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_1_A" checked={values.IQ_1_1_A} onChange={handleCheckBox} /> {ti('IQ_1_1_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_1_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_1_B" checked={values.IQ_1_1_B} onChange={handleCheckBox} /> {ti('IQ_1_1_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_1_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_1_C" checked={values.IQ_1_1_C} onChange={handleCheckBox} /> {ti('IQ_1_1_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_1_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_1_D" checked={values.IQ_1_1_D} onChange={handleCheckBox} /> {ti('IQ_1_1_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">2. {ti('IQ_1_2')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_2_A" checked={values.IQ_1_2_A} onChange={handleCheckBox} /> {ti('IQ_1_2_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_2_B" checked={values.IQ_1_2_B} onChange={handleCheckBox} /> {ti('IQ_1_2_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_2_C" checked={values.IQ_1_2_C} onChange={handleCheckBox} /> {ti('IQ_1_2_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_2_D" checked={values.IQ_1_2_D} onChange={handleCheckBox} /> {ti('IQ_1_2_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">3. {ti('IQ_1_3')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_3_A" checked={values.IQ_1_3_A} onChange={handleCheckBox} /> {ti('IQ_1_3_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_3_B" checked={values.IQ_1_3_B} onChange={handleCheckBox} /> {ti('IQ_1_3_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_3_C" checked={values.IQ_1_3_C} onChange={handleCheckBox} /> {ti('IQ_1_3_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_3_D" checked={values.IQ_1_3_D} onChange={handleCheckBox} /> {ti('IQ_1_3_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">4. {ti('IQ_1_4')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_4_A" checked={values.IQ_1_4_A} onChange={handleCheckBox} /> {ti('IQ_1_4_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_4_B" checked={values.IQ_1_4_B} onChange={handleCheckBox} /> {ti('IQ_1_4_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_4_C" checked={values.IQ_1_4_C} onChange={handleCheckBox} /> {ti('IQ_1_4_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_4_D" checked={values.IQ_1_4_D} onChange={handleCheckBox} /> {ti('IQ_1_4_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">5. {ti('IQ_1_5')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_5_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_5_A" checked={values.IQ_1_5_A} onChange={handleCheckBox} /> {ti('IQ_1_5_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_5_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_5_B" checked={values.IQ_1_5_B} onChange={handleCheckBox} /> {ti('IQ_1_5_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_5_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_5_C" checked={values.IQ_1_5_C} onChange={handleCheckBox} /> {ti('IQ_1_5_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_5_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_5_D" checked={values.IQ_1_5_D} onChange={handleCheckBox} /> {ti('IQ_1_5_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">6. {ti('IQ_1_6')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_6_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_6_A" checked={values.IQ_1_6_A} onChange={handleCheckBox} /> {ti('IQ_1_6_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_6_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_6_B" checked={values.IQ_1_6_B} onChange={handleCheckBox} /> {ti('IQ_1_6_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_6_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_6_C" checked={values.IQ_1_6_C} onChange={handleCheckBox} /> {ti('IQ_1_6_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_6_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_6_D" checked={values.IQ_1_6_D} onChange={handleCheckBox} /> {ti('IQ_1_6_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">7. {ti('IQ_1_7')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_7_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_7_A" checked={values.IQ_1_7_A} onChange={handleCheckBox} /> {ti('IQ_1_7_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_7_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_7_B" checked={values.IQ_1_7_B} onChange={handleCheckBox} /> {ti('IQ_1_7_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_7_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_7_C" checked={values.IQ_1_7_C} onChange={handleCheckBox} /> {ti('IQ_1_7_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_7_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_7_D" checked={values.IQ_1_7_D} onChange={handleCheckBox} /> {ti('IQ_1_7_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">8. {ti('IQ_1_8')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_8_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_8_A" checked={values.IQ_1_8_A} onChange={handleCheckBox} /> {ti('IQ_1_8_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_8_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_8_B" checked={values.IQ_1_8_B} onChange={handleCheckBox} /> {ti('IQ_1_8_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_8_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_8_C" checked={values.IQ_1_8_C} onChange={handleCheckBox} /> {ti('IQ_1_8_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_8_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_8_D" checked={values.IQ_1_8_D} onChange={handleCheckBox} /> {ti('IQ_1_8_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">9. {ti('IQ_1_9')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_9_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_9_A" checked={values.IQ_1_9_A} onChange={handleCheckBox} /> {ti('IQ_1_9_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_9_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_9_B" checked={values.IQ_1_9_B} onChange={handleCheckBox} /> {ti('IQ_1_9_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_9_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_9_C" checked={values.IQ_1_9_C} onChange={handleCheckBox} /> {ti('IQ_1_9_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_9_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_9_D" checked={values.IQ_1_9_D} onChange={handleCheckBox} /> {ti('IQ_1_9_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">10. {ti('IQ_1_10')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_10_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_10_A" checked={values.IQ_1_10_A} onChange={handleCheckBox} /> {ti('IQ_1_10_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_10_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_10_B" checked={values.IQ_1_10_B} onChange={handleCheckBox} /> {ti('IQ_1_10_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_10_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_10_C" checked={values.IQ_1_10_C} onChange={handleCheckBox} /> {ti('IQ_1_10_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_10_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_10_D" checked={values.IQ_1_10_D} onChange={handleCheckBox} /> {ti('IQ_1_10_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">11. {ti('IQ_1_11')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_11_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_11_A" checked={values.IQ_1_11_A} onChange={handleCheckBox} /> {ti('IQ_1_11_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_11_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_11_B" checked={values.IQ_1_11_B} onChange={handleCheckBox} /> {ti('IQ_1_11_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_11_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_11_C" checked={values.IQ_1_11_C} onChange={handleCheckBox} /> {ti('IQ_1_11_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_11_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_11_D" checked={values.IQ_1_11_D} onChange={handleCheckBox} /> {ti('IQ_1_11_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">12. {ti('IQ_1_12')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit">{ti('IQ_1_12_note1')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit">{ti('IQ_1_12_note2')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_1_12_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_12_A" checked={values.IQ_1_12_A} onChange={handleCheckBox} /> {ti('IQ_1_12_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="inherit" className={!values.IQ_1_12_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_12_B" checked={values.IQ_1_12_B} onChange={handleCheckBox} /> {ti('IQ_1_12_B')}
          </Typography>
          <TextField
            label="upřesněte"
            error={values.IQ_1_12_B && !values.IQ_1_12_B_input ? true : false}
            className={classes.paddingLeft}
            id={'IQ_1_12_B_input'}
            disabled={!values.IQ_1_12_B}
            onChange={handleCheckBox}
            value={values.IQ_1_12_B_input}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="inherit" className={!values.IQ_1_12_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_1_12_C" checked={values.IQ_1_12_C} onChange={handleCheckBox} /> {ti('IQ_1_12_C')}
          </Typography>
          <TextField
            label="upřesněte"
            error={values.IQ_1_12_C && !values.IQ_1_12_C_input ? true : false}
            className={classes.paddingLeft}
            id={'IQ_1_12_C_input'}
            disabled={!values.IQ_1_12_C}
            onChange={handleCheckBox}
            value={values.IQ_1_12_C_input}
          />
        </Grid>
      </Grid>
    </>
  )

  const iq_1 = (
    <>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          1. {ti('IQ_1_1')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_1_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_1_A" checked={values.IQ_1_1_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_1_A')}
        />
        <FormControlLabel
          className={values.IQ_1_1_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_1_B" checked={values.IQ_1_1_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_1_B')}
        />
        <FormControlLabel
          className={values.IQ_1_1_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_1_C" checked={values.IQ_1_1_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_1_C')}
        />
        <FormControlLabel
          className={values.IQ_1_1_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_1_D" checked={values.IQ_1_1_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_1_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          2. {ti('IQ_1_2')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_2_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_2_A" checked={values.IQ_1_2_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_2_A')}
        />
        <FormControlLabel
          className={values.IQ_1_2_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_2_B" checked={values.IQ_1_2_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_2_B')}
        />
        <FormControlLabel
          className={values.IQ_1_2_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_2_C" checked={values.IQ_1_2_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_2_C')}
        />
        <FormControlLabel
          className={values.IQ_1_2_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_2_D" checked={values.IQ_1_2_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_2_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          3. {ti('IQ_1_3')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_3_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_3_A" checked={values.IQ_1_3_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_3_A')}
        />
        <FormControlLabel
          className={values.IQ_1_3_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_3_B" checked={values.IQ_1_3_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_3_B')}
        />
        <FormControlLabel
          className={values.IQ_1_3_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_3_C" checked={values.IQ_1_3_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_3_C')}
        />
        <FormControlLabel
          className={values.IQ_1_3_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_3_D" checked={values.IQ_1_3_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_3_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          4. {ti('IQ_1_4')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_4_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_4_A" checked={values.IQ_1_4_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_4_A')}
        />
        <FormControlLabel
          className={values.IQ_1_4_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_4_B" checked={values.IQ_1_4_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_4_B')}
        />
        <FormControlLabel
          className={values.IQ_1_4_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_4_C" checked={values.IQ_1_4_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_4_C')}
        />
        <FormControlLabel
          className={values.IQ_1_4_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_4_D" checked={values.IQ_1_4_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_4_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          5. {ti('IQ_1_5')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_5_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_5_A" checked={values.IQ_1_5_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_5_A')}
        />
        <FormControlLabel
          className={values.IQ_1_5_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_5_B" checked={values.IQ_1_5_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_5_B')}
        />
        <FormControlLabel
          className={values.IQ_1_5_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_5_C" checked={values.IQ_1_5_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_5_C')}
        />
        <FormControlLabel
          className={values.IQ_1_5_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_5_D" checked={values.IQ_1_5_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_5_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          6. {ti('IQ_1_6')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_6_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_6_A" checked={values.IQ_1_6_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_6_A')}
        />
        <FormControlLabel
          className={values.IQ_1_6_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_6_B" checked={values.IQ_1_6_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_6_B')}
        />
        <FormControlLabel
          className={values.IQ_1_6_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_6_C" checked={values.IQ_1_6_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_6_C')}
        />
        <FormControlLabel
          className={values.IQ_1_6_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_6_D" checked={values.IQ_1_6_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_6_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          7. {ti('IQ_1_7')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_7_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_7_A" checked={values.IQ_1_7_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_7_A')}
        />
        <FormControlLabel
          className={values.IQ_1_7_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_7_B" checked={values.IQ_1_7_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_7_B')}
        />
        <FormControlLabel
          className={values.IQ_1_7_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_7_C" checked={values.IQ_1_7_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_7_C')}
        />
        <FormControlLabel
          className={values.IQ_1_7_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_7_D" checked={values.IQ_1_7_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_7_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          8. {ti('IQ_1_8')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_8_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_8_A" checked={values.IQ_1_8_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_8_A')}
        />
        <FormControlLabel
          className={values.IQ_1_8_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_8_B" checked={values.IQ_1_8_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_8_B')}
        />
        <FormControlLabel
          className={values.IQ_1_8_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_8_C" checked={values.IQ_1_8_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_8_C')}
        />
        <FormControlLabel
          className={values.IQ_1_8_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_8_D" checked={values.IQ_1_8_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_8_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          9. {ti('IQ_1_9')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_9_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_9_A" checked={values.IQ_1_9_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_9_A')}
        />
        <FormControlLabel
          className={values.IQ_1_9_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_9_B" checked={values.IQ_1_9_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_9_B')}
        />
        <FormControlLabel
          className={values.IQ_1_9_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_9_C" checked={values.IQ_1_9_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_9_C')}
        />
        <FormControlLabel
          className={values.IQ_1_9_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_9_D" checked={values.IQ_1_9_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_9_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          10. {ti('IQ_1_10')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_10_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_10_A" checked={values.IQ_1_10_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_10_A')}
        />
        <FormControlLabel
          className={values.IQ_1_10_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_10_B" checked={values.IQ_1_10_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_10_B')}
        />
        <FormControlLabel
          className={values.IQ_1_10_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_10_C" checked={values.IQ_1_10_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_10_C')}
        />
        <FormControlLabel
          className={values.IQ_1_10_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_10_D" checked={values.IQ_1_10_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_10_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          11. {ti('IQ_1_11')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_11_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_11_A" checked={values.IQ_1_11_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_11_A')}
        />
        <FormControlLabel
          className={values.IQ_1_11_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_11_B" checked={values.IQ_1_11_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_11_B')}
        />
        <FormControlLabel
          className={values.IQ_1_11_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_11_C" checked={values.IQ_1_11_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_11_C')}
        />
        <FormControlLabel
          className={values.IQ_1_11_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_11_D" checked={values.IQ_1_11_D} onChange={handleCheckBox} />}
          label={ti('IQ_1_11_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          12. {ti('IQ_1_12')}
        </Typography>
        <FormControlLabel
          className={values.IQ_1_12_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_12_A" checked={values.IQ_1_12_A} onChange={handleCheckBox} />}
          label={ti('IQ_1_12_A')}
        />
        <FormControlLabel
          className={values.IQ_1_12_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_12_B" checked={values.IQ_1_12_B} onChange={handleCheckBox} />}
          label={ti('IQ_1_12_B')}
        />
        <TextField
          label={t('SPECIFY')}
          error={values.IQ_1_12_B && !values.IQ_1_12_B_input ? true : false}
          id={'IQ_1_12_B_input'}
          disabled={!values.IQ_1_12_B}
          onChange={handleCheckBox}
          value={values.IQ_1_12_B_input}
          className={classes.textInput}
        />
        <FormControlLabel
          className={values.IQ_1_12_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_1_12_C" checked={values.IQ_1_12_C} onChange={handleCheckBox} />}
          label={ti('IQ_1_12_C')}
        />
        <TextField
          label={t('SPECIFY')}
          error={values.IQ_1_12_C && !values.IQ_1_12_C_input ? true : false}
          id={'IQ_1_12_C_input'}
          disabled={!values.IQ_1_12_C}
          onChange={handleCheckBox}
          value={values.IQ_1_12_C_input}
          className={classes.textInput}
        />
      </span>
    </>
  )

  const iq_22 = (
    <>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <Typography variant="inherit">1. {ti('IQ_2_1')}</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_2_1_valid ? classes.orange : classes.paddingLeft}>
            a:{' '}
            <Checkbox id="IQ_2_1_A" checked={values.IQ_2_1_A} onChange={handleCheckBox} disabled={skillsDisabled01} />{' '}
            {ti('IQ_2_1_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={12} />

        <Grid item xs={3} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_2_1_h1')}</Typography>{' '}
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_2_1_h2')}</Typography>{' '}
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_2_1_h3')}</Typography>{' '}
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_2_1_h4')}</Typography>{' '}
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit">{ti('IQ_2_2')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_2_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_2_A" checked={values.IQ_2_2_A} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_2_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_2_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_2_B" checked={values.IQ_2_2_B} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_2_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_2_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_2_C" checked={values.IQ_2_2_C} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_2_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_3_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_3_A" checked={values.IQ_2_3_A} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_3_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_3_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_3_B" checked={values.IQ_2_3_B} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_3_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_3_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_3_C" checked={values.IQ_2_3_C} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_3_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_4_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_4_A" checked={values.IQ_2_4_A} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_4_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_4_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_4_B" checked={values.IQ_2_4_B} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_4_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_4_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_4_C" checked={values.IQ_2_4_C} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_4_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit">{ti('IQ_2_5')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_5_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_5_A" checked={values.IQ_2_5_A} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_5_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_5_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_5_B" checked={values.IQ_2_5_B} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_5_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_5_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_5_C" checked={values.IQ_2_5_C} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_5_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_6_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_6_A" checked={values.IQ_2_6_A} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_6_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_6_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_6_B" checked={values.IQ_2_6_B} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_6_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_6_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_6_C" checked={values.IQ_2_6_C} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_6_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_7_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_7_A" checked={values.IQ_2_7_A} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_7_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_7_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_7_B" checked={values.IQ_2_7_B} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_7_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_7_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_7_C" checked={values.IQ_2_7_C} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_7_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit">{ti('IQ_2_8')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_8_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_8_A" checked={values.IQ_2_8_A} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_8_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_8_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_8_B" checked={values.IQ_2_8_B} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_8_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_8_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_8_C" checked={values.IQ_2_8_C} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_8_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_9_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_9_A" checked={values.IQ_2_9_A} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_9_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_9_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_9_B" checked={values.IQ_2_9_B} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_9_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_9_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox id="IQ_2_9_C" checked={values.IQ_2_9_C} onChange={handleCheckBox} disabled={skillsDisabled02} />
              {ti('IQ_2_9_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_10_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_10_A"
                checked={values.IQ_2_10_A}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_10_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_10_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_10_B"
                checked={values.IQ_2_10_B}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_10_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_10_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_10_C"
                checked={values.IQ_2_10_C}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_10_C')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit">{ti('IQ_2_11')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_11_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_11_A"
                checked={values.IQ_2_11_A}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_11_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_11_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_11_B"
                checked={values.IQ_2_11_B}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_11_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_11_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_11_C"
                checked={values.IQ_2_11_C}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_11_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_12_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_12_A"
                checked={values.IQ_2_12_A}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_12_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_12_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_12_B"
                checked={values.IQ_2_12_B}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_12_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_12_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_12_C"
                checked={values.IQ_2_12_C}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_12_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_13_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_13_A"
                checked={values.IQ_2_13_A}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_13_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_13_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_13_B"
                checked={values.IQ_2_13_B}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_13_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_13_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_13_C"
                checked={values.IQ_2_13_C}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_13_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit">{ti('IQ_2_14')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_14_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_14_A"
                checked={values.IQ_2_14_A}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_14_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_14_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_14_B"
                checked={values.IQ_2_14_B}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_14_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_14_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_14_C"
                checked={values.IQ_2_14_C}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_14_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_15_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_15_A"
                checked={values.IQ_2_15_A}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_15_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_15_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_15_B"
                checked={values.IQ_2_15_B}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_15_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_15_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_15_C"
                checked={values.IQ_2_15_C}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_15_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_16_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_16_A"
                checked={values.IQ_2_16_A}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_16_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_16_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_16_B"
                checked={values.IQ_2_16_B}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_16_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_16_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_16_C"
                checked={values.IQ_2_16_C}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_16_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit">{ti('IQ_2_17')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_17_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_17_A"
                checked={values.IQ_2_17_A}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_17_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_17_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_17_B"
                checked={values.IQ_2_17_B}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_17_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_17_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_17_C"
                checked={values.IQ_2_17_C}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_17_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_18_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_18_A"
                checked={values.IQ_2_18_A}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_18_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_18_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_18_B"
                checked={values.IQ_2_18_B}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_18_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_18_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_18_C"
                checked={values.IQ_2_18_C}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_18_C')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.blackBorder}>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_19_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_19_A"
                checked={values.IQ_2_19_A}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_19_A')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_19_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_19_B"
                checked={values.IQ_2_19_B}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_19_B')}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="inherit" className={!values.IQ_2_19_valid ? classes.orange : classes.paddingLeft}>
              <Checkbox
                id="IQ_2_19_C"
                checked={values.IQ_2_19_C}
                onChange={handleCheckBox}
                disabled={skillsDisabled02}
              />
              {ti('IQ_2_19_C')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )

  const iq_2 = (
    <div className={classes.iqtwo}>
      {' '}
      {/* TODO remove and do it better */}
      <Typography variant="inherit" className={classes.questionTitle}>
        {ti('IQ_2_1')}
      </Typography>
      <span>
        <FormControlLabel
          className={values.IQ_2_1_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_2_1_A" checked={values.IQ_2_1_A} onChange={handleCheckBox} />}
          label={ti('IQ_2_1_A')}
          disabled={skillsDisabled01}
        />
      </span>
      <div className={classes.skillsWrapper}>
        <Typography variant="inherit" className={classes.skillsTitle}>
          {ti('IQ_2_2')}
        </Typography>
        <div className={classes.skillsSubtitleWrapper}>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h2')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h3')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h4')}
          </Typography>
        </div>
        <div className={classes.skillsSubcontent}>
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_2_A" checked={values.IQ_2_2_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_2_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_3_A" checked={values.IQ_2_3_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_3_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_4_A" checked={values.IQ_2_4_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_4_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_2_B" checked={values.IQ_2_2_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_2_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_3_B" checked={values.IQ_2_3_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_3_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_4_B" checked={values.IQ_2_4_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_4_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_2_C" checked={values.IQ_2_2_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_2_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_3_C" checked={values.IQ_2_3_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_3_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_4_C" checked={values.IQ_2_4_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_4_C')}
          />
        </div>
      </div>
      <div className={classes.skillsWrapper}>
        <Typography variant="inherit" className={classes.skillsTitle}>
          {ti('IQ_2_5')}
        </Typography>
        <div className={classes.skillsSubtitleWrapper}>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h2')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h3')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h4')}
          </Typography>
        </div>
        <div className={classes.skillsSubcontent}>
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_5_A" checked={values.IQ_2_5_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_5_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_6_A" checked={values.IQ_2_6_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_6_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_7_A" checked={values.IQ_2_7_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_7_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_5_B" checked={values.IQ_2_5_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_5_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_6_B" checked={values.IQ_2_6_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_6_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_7_B" checked={values.IQ_2_7_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_7_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_5_C" checked={values.IQ_2_5_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_5_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_6_C" checked={values.IQ_2_6_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_6_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_7_C" checked={values.IQ_2_7_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_7_C')}
          />
        </div>
      </div>
      <div className={classes.skillsWrapper}>
        <Typography variant="inherit" className={classes.skillsTitle}>
          {ti('IQ_2_8')}
        </Typography>
        <div className={classes.skillsSubtitleWrapper}>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h2')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h3')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h4')}
          </Typography>
        </div>
        <div className={classes.skillsSubcontent}>
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_8_A" checked={values.IQ_2_8_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_8_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_9_A" checked={values.IQ_2_9_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_9_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_10_A" checked={values.IQ_2_10_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_10_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_8_B" checked={values.IQ_2_8_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_8_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_9_B" checked={values.IQ_2_9_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_9_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_10_B" checked={values.IQ_2_10_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_10_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_8_C" checked={values.IQ_2_8_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_8_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_9_C" checked={values.IQ_2_9_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_9_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_10_C" checked={values.IQ_2_10_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_10_C')}
          />
        </div>
      </div>
      <div className={classes.skillsWrapper}>
        <Typography variant="inherit" className={classes.skillsTitle}>
          {ti('IQ_2_11')}
        </Typography>
        <div className={classes.skillsSubtitleWrapper}>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h2')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h3')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h4')}
          </Typography>
        </div>
        <div className={classes.skillsSubcontent}>
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_11_A" checked={values.IQ_2_11_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_11_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_12_A" checked={values.IQ_2_12_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_12_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_13_A" checked={values.IQ_2_13_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_13_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_11_B" checked={values.IQ_2_11_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_11_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_12_B" checked={values.IQ_2_12_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_12_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_13_B" checked={values.IQ_2_13_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_13_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_11_C" checked={values.IQ_2_11_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_11_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_12_C" checked={values.IQ_2_12_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_12_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_13_C" checked={values.IQ_2_13_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_13_C')}
          />
        </div>
      </div>
      <div className={classes.skillsWrapper}>
        <Typography variant="inherit" className={classes.skillsTitle}>
          {ti('IQ_2_14')}
        </Typography>
        <div className={classes.skillsSubtitleWrapper}>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h2')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h3')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h4')}
          </Typography>
        </div>
        <div className={classes.skillsSubcontent}>
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_14_A" checked={values.IQ_2_14_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_14_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_15_A" checked={values.IQ_2_15_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_15_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_16_A" checked={values.IQ_2_16_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_16_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_14_B" checked={values.IQ_2_14_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_14_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_15_B" checked={values.IQ_2_15_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_15_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_16_B" checked={values.IQ_2_16_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_16_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_14_C" checked={values.IQ_2_14_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_14_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_15_C" checked={values.IQ_2_15_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_15_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_16_C" checked={values.IQ_2_16_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_16_C')}
          />
        </div>
      </div>
      <div className={classes.skillsWrapper}>
        <Typography variant="inherit" className={classes.skillsTitle}>
          {ti('IQ_2_17')}
        </Typography>
        <div className={classes.skillsSubtitleWrapper}>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h2')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h3')}
          </Typography>
          <Typography variant="inherit" className={classes.skillsSubtitle}>
            {ti('IQ_2_1_h4')}
          </Typography>
        </div>
        <div className={classes.skillsSubcontent}>
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_17_A" checked={values.IQ_2_17_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_17_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_18_A" checked={values.IQ_2_18_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_18_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_19_A" checked={values.IQ_2_19_A} onChange={handleCheckBox} />}
            label={ti('IQ_2_19_A')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_17_B" checked={values.IQ_2_17_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_17_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_18_B" checked={values.IQ_2_18_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_18_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_19_B" checked={values.IQ_2_19_B} onChange={handleCheckBox} />}
            label={ti('IQ_2_19_B')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_17_C" checked={values.IQ_2_17_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_17_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_18_C" checked={values.IQ_2_18_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_18_C')}
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            className={classes.skillsQuestion}
            control={<Checkbox id="IQ_2_19_C" checked={values.IQ_2_19_C} onChange={handleCheckBox} />}
            label={ti('IQ_2_19_C')}
          />
        </div>
      </div>
      <Typography>{`* ${ti('IQ_2_1_h4_note')}`}</Typography>
    </div>
  )

  const iq_23 = (
    <>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <Typography variant="inherit">1. {ti('IQ_3_1')}</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit">{ti('IQ_3_1_note')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_1_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_1_A" checked={values.IQ_3_1_A} onChange={handleCheckBox} /> {ti('IQ_3_1_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_1_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_1_B" checked={values.IQ_3_1_B} onChange={handleCheckBox} /> {ti('IQ_3_1_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_1_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_1_C" checked={values.IQ_3_1_C} onChange={handleCheckBox} /> {ti('IQ_3_1_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_1_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_1_D" checked={values.IQ_3_1_D} onChange={handleCheckBox} /> {ti('IQ_3_1_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">2. {ti('IQ_3_2')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_2_A" checked={values.IQ_3_2_A} onChange={handleCheckBox} /> {ti('IQ_3_2_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_2_B" checked={values.IQ_3_2_B} onChange={handleCheckBox} /> {ti('IQ_3_2_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_2_C" checked={values.IQ_3_2_C} onChange={handleCheckBox} /> {ti('IQ_3_2_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_2_D" checked={values.IQ_3_2_D} onChange={handleCheckBox} /> {ti('IQ_3_2_D')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_2_valid ? classes.orange : classes.paddingLeft}>
            e: <Checkbox id="IQ_3_2_E" checked={values.IQ_3_2_E} onChange={handleCheckBox} /> {ti('IQ_3_2_E')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">3. {ti('IQ_3_3')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit">{ti('IQ_3_3_note')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_3_A" checked={values.IQ_3_3_A} onChange={handleCheckBox} /> {ti('IQ_3_3_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_3_B" checked={values.IQ_3_3_B} onChange={handleCheckBox} /> {ti('IQ_3_3_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_3_C" checked={values.IQ_3_3_C} onChange={handleCheckBox} /> {ti('IQ_3_3_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_3_D" checked={values.IQ_3_3_D} onChange={handleCheckBox} /> {ti('IQ_3_3_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">4. {ti('IQ_3_4')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_4_A" checked={values.IQ_3_4_A} onChange={handleCheckBox} /> {ti('IQ_3_4_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_4_B" checked={values.IQ_3_4_B} onChange={handleCheckBox} /> {ti('IQ_3_4_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_4_C" checked={values.IQ_3_4_C} onChange={handleCheckBox} /> {ti('IQ_3_4_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_4_D" checked={values.IQ_3_4_D} onChange={handleCheckBox} /> {ti('IQ_3_4_D')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="inherit" className={!values.IQ_3_4_valid ? classes.orange : classes.paddingLeft}>
            e: <Checkbox id="IQ_3_4_E" checked={values.IQ_3_4_E} onChange={handleCheckBox} /> {ti('IQ_3_4_E')}
          </Typography>
          <TextField
            label="upřesněte"
            error={values.IQ_3_4_E && !values.IQ_3_4_E_input ? true : false}
            className={classes.paddingLeft}
            id={'IQ_3_4_E_input'}
            disabled={!values.IQ_3_4_E}
            onChange={handleCheckBox}
            value={values.IQ_3_4_E_input}
          />
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">5. {ti('IQ_3_5')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_5_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_5_A" checked={values.IQ_3_5_A} onChange={handleCheckBox} /> {ti('IQ_3_5_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_5_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_5_B" checked={values.IQ_3_5_B} onChange={handleCheckBox} /> {ti('IQ_3_5_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_5_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_5_C" checked={values.IQ_3_5_C} onChange={handleCheckBox} /> {ti('IQ_3_5_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_5_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_3_5_D" checked={values.IQ_3_5_D} onChange={handleCheckBox} /> {ti('IQ_3_5_D')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_3_5_valid ? classes.orange : classes.paddingLeft}>
            e: <Checkbox id="IQ_3_5_E" checked={values.IQ_3_5_E} onChange={handleCheckBox} /> {ti('IQ_3_5_E')}
          </Typography>{' '}
        </Grid>
      </Grid>
    </>
  )

  const iq_3 = (
    <>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          1. {ti('IQ_3_1')}
        </Typography>
        <Typography variant="inherit" className={classes.questionNote}>
          {ti('IQ_3_1_note')}
        </Typography>
        <FormControlLabel
          className={values.IQ_3_1_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_1_A" checked={values.IQ_3_1_A} onChange={handleCheckBox} />}
          label={ti('IQ_3_1_A')}
        />
        <FormControlLabel
          className={values.IQ_3_1_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_1_B" checked={values.IQ_3_1_B} onChange={handleCheckBox} />}
          label={ti('IQ_3_1_B')}
        />
        <FormControlLabel
          className={values.IQ_3_1_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_1_C" checked={values.IQ_3_1_C} onChange={handleCheckBox} />}
          label={ti('IQ_3_1_C')}
        />
        <FormControlLabel
          className={values.IQ_3_1_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_1_D" checked={values.IQ_3_1_D} onChange={handleCheckBox} />}
          label={ti('IQ_3_1_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          2. {ti('IQ_3_2')}
        </Typography>
        <FormControlLabel
          className={values.IQ_3_2_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_2_A" checked={values.IQ_3_2_A} onChange={handleCheckBox} />}
          label={ti('IQ_3_2_A')}
        />
        <FormControlLabel
          className={values.IQ_3_2_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_2_B" checked={values.IQ_3_2_B} onChange={handleCheckBox} />}
          label={ti('IQ_3_2_B')}
        />
        <FormControlLabel
          className={values.IQ_3_2_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_2_C" checked={values.IQ_3_2_C} onChange={handleCheckBox} />}
          label={ti('IQ_3_2_C')}
        />
        <FormControlLabel
          className={values.IQ_3_2_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_2_D" checked={values.IQ_3_2_D} onChange={handleCheckBox} />}
          label={ti('IQ_3_2_D')}
        />
        <FormControlLabel
          className={values.IQ_3_2_E ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_2_E" checked={values.IQ_3_2_E} onChange={handleCheckBox} />}
          label={ti('IQ_3_2_E')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          3. {ti('IQ_3_3')}
        </Typography>

        <Typography variant="inherit" className={classes.questionNote}>
          {ti('IQ_3_3_note')}
        </Typography>
        <FormControlLabel
          className={values.IQ_3_3_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_3_A" checked={values.IQ_3_3_A} onChange={handleCheckBox} />}
          label={ti('IQ_3_3_A')}
        />
        <FormControlLabel
          className={values.IQ_3_3_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_3_B" checked={values.IQ_3_3_B} onChange={handleCheckBox} />}
          label={ti('IQ_3_3_B')}
        />
        <FormControlLabel
          className={values.IQ_3_3_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_3_C" checked={values.IQ_3_3_C} onChange={handleCheckBox} />}
          label={ti('IQ_3_3_C')}
        />
        <FormControlLabel
          className={values.IQ_3_3_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_3_D" checked={values.IQ_3_3_D} onChange={handleCheckBox} />}
          label={ti('IQ_3_3_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          4. {ti('IQ_3_4')}
        </Typography>
        <FormControlLabel
          className={values.IQ_3_4_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_4_A" checked={values.IQ_3_4_A} onChange={handleCheckBox} />}
          label={ti('IQ_3_4_A')}
        />
        <FormControlLabel
          className={values.IQ_3_4_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_4_B" checked={values.IQ_3_4_B} onChange={handleCheckBox} />}
          label={ti('IQ_3_4_B')}
        />
        <FormControlLabel
          className={values.IQ_3_4_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_4_C" checked={values.IQ_3_4_C} onChange={handleCheckBox} />}
          label={ti('IQ_3_4_C')}
        />
        <FormControlLabel
          className={values.IQ_3_4_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_4_D" checked={values.IQ_3_4_D} onChange={handleCheckBox} />}
          label={ti('IQ_3_4_D')}
        />
        <FormControlLabel
          className={values.IQ_3_4_E ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_4_E" checked={values.IQ_3_4_E} onChange={handleCheckBox} />}
          label={ti('IQ_3_4_E')}
        />
        <TextField
          label={t('SPECIFY')}
          error={values.IQ_3_4_E && !values.IQ_3_4_E_input ? true : false}
          id={'IQ_3_4_E_input'}
          disabled={!values.IQ_3_4_E}
          onChange={handleCheckBox}
          value={values.IQ_3_4_E_input}
          className={classes.textInput}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          5. {ti('IQ_3_5')}
        </Typography>
        <FormControlLabel
          className={values.IQ_3_5_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_5_A" checked={values.IQ_3_5_A} onChange={handleCheckBox} />}
          label={ti('IQ_3_5_A')}
        />
        <FormControlLabel
          className={values.IQ_3_5_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_5_B" checked={values.IQ_3_5_B} onChange={handleCheckBox} />}
          label={ti('IQ_3_5_B')}
        />
        <FormControlLabel
          className={values.IQ_3_5_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_5_C" checked={values.IQ_3_5_C} onChange={handleCheckBox} />}
          label={ti('IQ_3_5_C')}
        />
        <FormControlLabel
          className={values.IQ_3_5_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_5_D" checked={values.IQ_3_5_D} onChange={handleCheckBox} />}
          label={ti('IQ_3_5_D')}
        />
        <FormControlLabel
          className={values.IQ_3_5_E ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_3_5_E" checked={values.IQ_3_5_E} onChange={handleCheckBox} />}
          label={ti('IQ_3_5_E')}
        />
      </span>
    </>
  )

  const iq_24 = (
    <>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <Typography variant="inherit">1. {ti('IQ_4_1')}</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_1_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_1_A" checked={values.IQ_4_1_A} onChange={handleCheckBox} /> {ti('IQ_4_1_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_1_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_1_B" checked={values.IQ_4_1_B} onChange={handleCheckBox} /> {ti('IQ_4_1_B')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">2. {ti('IQ_4_2')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit">{ti('IQ_4_2_note')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_2_A" checked={values.IQ_4_2_A} onChange={handleCheckBox} /> {ti('IQ_4_2_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_2_B" checked={values.IQ_4_2_B} onChange={handleCheckBox} /> {ti('IQ_4_2_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_2_C" checked={values.IQ_4_2_C} onChange={handleCheckBox} /> {ti('IQ_4_2_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_2_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_2_D" checked={values.IQ_4_2_D} onChange={handleCheckBox} /> {ti('IQ_4_2_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">3. {ti('IQ_4_3')}</Typography>{' '}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          {' '}
          <img src={graph} />{' '}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_3_A" checked={values.IQ_4_3_A} onChange={handleCheckBox} /> {ti('IQ_4_3_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_3_B" checked={values.IQ_4_3_B} onChange={handleCheckBox} /> {ti('IQ_4_3_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_3_C" checked={values.IQ_4_3_C} onChange={handleCheckBox} /> {ti('IQ_4_3_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_3_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_3_D" checked={values.IQ_4_3_D} onChange={handleCheckBox} /> {ti('IQ_4_3_D')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_3_valid ? classes.orange : classes.paddingLeft}>
            e: <Checkbox id="IQ_4_3_E" checked={values.IQ_4_3_E} onChange={handleCheckBox} /> {ti('IQ_4_3_E')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">4. {ti('IQ_4_4')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_4_A" checked={values.IQ_4_4_A} onChange={handleCheckBox} /> {ti('IQ_4_4_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_4_B" checked={values.IQ_4_4_B} onChange={handleCheckBox} /> {ti('IQ_4_4_B')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_4_C" checked={values.IQ_4_4_C} onChange={handleCheckBox} /> {ti('IQ_4_4_C')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_4_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_4_D" checked={values.IQ_4_4_D} onChange={handleCheckBox} /> {ti('IQ_4_4_D')}
          </Typography>{' '}
        </Grid>

        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">5. {ti('IQ_4_5')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit">{ti('IQ_4_5_note')}</Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_5_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_5_B" checked={values.IQ_4_5_A} onChange={handleCheckBox} /> {ti('IQ_4_5_A')}
          </Typography>{' '}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          {' '}
          <Typography variant="inherit" className={!values.IQ_4_5_valid ? classes.orange : classes.paddingLeft}>
            <Checkbox id="IQ_4_5_B" checked={values.IQ_4_5_B} onChange={handleCheckBox} /> {ti('IQ_4_5_B')}
          </Typography>{' '}
        </Grid>
      </Grid>
    </>
  )

  const iq_4 = (
    <>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          1. {ti('IQ_4_1')}
        </Typography>
        <FormControlLabel
          className={values.IQ_4_1_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_1_A" checked={values.IQ_4_1_A} onChange={handleCheckBox} />}
          label={ti('IQ_4_1_A')}
        />
        <FormControlLabel
          className={values.IQ_4_1_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_1_B" checked={values.IQ_4_1_B} onChange={handleCheckBox} />}
          label={ti('IQ_4_1_B')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          2. {ti('IQ_4_2')}
        </Typography>

        <Typography variant="inherit" className={classes.questionNote}>
          {ti('IQ_4_2_note')}
        </Typography>
        <FormControlLabel
          className={values.IQ_4_2_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_2_A" checked={values.IQ_4_2_A} onChange={handleCheckBox} />}
          label={ti('IQ_4_2_A')}
        />
        <FormControlLabel
          className={values.IQ_4_2_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_2_B" checked={values.IQ_4_2_B} onChange={handleCheckBox} />}
          label={ti('IQ_4_2_B')}
        />
        <FormControlLabel
          className={values.IQ_4_2_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_2_C" checked={values.IQ_4_2_C} onChange={handleCheckBox} />}
          label={ti('IQ_4_2_C')}
        />
        <FormControlLabel
          className={values.IQ_4_2_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_2_D" checked={values.IQ_4_2_D} onChange={handleCheckBox} />}
          label={ti('IQ_4_2_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          3. {ti('IQ_4_3')}
        </Typography>
        <img src={graph} />
        <FormControlLabel
          className={values.IQ_4_3_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_3_A" checked={values.IQ_4_3_A} onChange={handleCheckBox} />}
          label={ti('IQ_4_3_A')}
        />
        <FormControlLabel
          className={values.IQ_4_3_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_3_B" checked={values.IQ_4_3_B} onChange={handleCheckBox} />}
          label={ti('IQ_4_3_B')}
        />
        <FormControlLabel
          className={values.IQ_4_3_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_3_C" checked={values.IQ_4_3_C} onChange={handleCheckBox} />}
          label={ti('IQ_4_3_C')}
        />
        <FormControlLabel
          className={values.IQ_4_3_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_3_D" checked={values.IQ_4_3_D} onChange={handleCheckBox} />}
          label={ti('IQ_4_3_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          4. {ti('IQ_4_4')}
        </Typography>
        <FormControlLabel
          className={values.IQ_4_4_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_4_A" checked={values.IQ_4_4_A} onChange={handleCheckBox} />}
          label={ti('IQ_4_4_A')}
        />
        <FormControlLabel
          className={values.IQ_4_4_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_4_B" checked={values.IQ_4_4_B} onChange={handleCheckBox} />}
          label={ti('IQ_4_4_B')}
        />
        <FormControlLabel
          className={values.IQ_4_4_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_4_C" checked={values.IQ_4_4_C} onChange={handleCheckBox} />}
          label={ti('IQ_4_4_C')}
        />
        <FormControlLabel
          className={values.IQ_4_4_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_4_D" checked={values.IQ_4_4_D} onChange={handleCheckBox} />}
          label={ti('IQ_4_4_D')}
        />
      </span>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          1. {ti('IQ_4_5')}
        </Typography>
        <Typography variant="inherit" className={classes.questionNote}>
          {ti('IQ_4_5_note')}
        </Typography>
        <FormControlLabel
          className={values.IQ_4_5_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_5_B" checked={values.IQ_4_5_A} onChange={handleCheckBox} />}
          label={ti('IQ_4_5_A')}
        />
        <FormControlLabel
          className={values.IQ_4_5_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_4_5_B" checked={values.IQ_4_5_B} onChange={handleCheckBox} />}
          label={ti('IQ_4_5_B')}
        />
      </span>
    </>
  )

  const iq_25 = (
    <>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <Typography variant="h4">{ti('IQ_5_1')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Typography variant="inherit">{ti('IQ_5_1_h1')}</Typography>{' '}
        </Grid>

        <Grid item xs={1} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">Č.</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_5_1_h2')}</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_5_1_h3')}</Typography>{' '}
        </Grid>
        <Grid item xs={7} />

        <Grid item xs={1} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">1.</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_5_1_a')}</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti(investmentResult.category)}</Typography>{' '}
        </Grid>
        <Grid item xs={7} />

        <Grid item xs={1} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">2.</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_5_2_a')}</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti(investmentResult.skills)}</Typography>{' '}
        </Grid>
        <Grid item xs={7} />

        <Grid item xs={1} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">3.</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_5_3_a')}</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti(investmentResult.horizonte)}</Typography>{' '}
        </Grid>
        <Grid item xs={7} />

        <Grid item xs={1} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">4.</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_5_4_a')}</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti(investmentResult.investmentGoal)}</Typography>{' '}
        </Grid>
        <Grid item xs={7} />

        <Grid item xs={1} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">5.</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_5_5_a')}</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti(investmentResult.lossAbility)}</Typography>{' '}
        </Grid>
        <Grid item xs={7} />

        <Grid item xs={1} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">6.</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_5_6_a')}</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti(investmentResult.risk)}</Typography>{' '}
        </Grid>
        <Grid item xs={7} />

        <Grid item xs={1} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">7.</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti('IQ_5_7_a')}</Typography>{' '}
        </Grid>
        <Grid item xs={2} className={classes.blackBorder}>
          {' '}
          <Typography variant="inherit">{ti(investmentResult.esg)}</Typography>{' '}
        </Grid>
        <Grid item xs={7} />

        <Grid item xs={12}>
          <Typography variant="h4">&nbsp;</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">{ti('IQ_5_8')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{ti('IQ_5_8_a') + ' ' + investmentResult.totalPoint}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">{ti('IQ_5_8_b') + ' ' + investmentResult.skillsPoint} </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">{ti('IQ_5_8_c') + ' ' + investmentResult.experiencesPoint}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{ti('IQ_5_8_d') + ' ' + investmentResult.financialGround}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{ti('IQ_5_8_e') + ' ' + investmentResult.financialGoals}</Typography>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={12}>
          <Typography variant="inherit">
            {ti('IQ_5_9') + ' - '} <strong>{ti(investmentResult.total)}</strong>
          </Typography>
        </Grid>
      </Grid>
    </>
  )

  const iq_5 = (
    <>
      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          1. {ti('IQ_6_1')}
        </Typography>
        <FormControlLabel
          className={values.IQ_6_1_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_1_A" checked={values.IQ_6_1_A} onChange={handleCheckBox} />}
          label={ti('IQ_6_1_A')}
        />
        <FormControlLabel
          className={values.IQ_6_1_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_1_B" checked={values.IQ_6_1_B} onChange={handleCheckBox} />}
          label={ti('IQ_6_1_B')}
        />
        <FormControlLabel
          className={values.IQ_6_1_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_1_C" checked={values.IQ_6_1_C} onChange={handleCheckBox} />}
          label={ti('IQ_6_1_C')}
        />
        <FormControlLabel
          className={values.IQ_6_1_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_1_D" checked={values.IQ_6_1_D} onChange={handleCheckBox} />}
          label={ti('IQ_6_1_D')}
        />
        <TextField
          label={t('SPECIFY')}
          error={values.IQ_6_1_D && !values.IQ_6_1_D ? true : false}
          id={'IQ_6_1_D_input'}
          disabled={!values.IQ_6_1_D}
          onChange={handleCheckBox}
          value={values.IQ_6_1_D_input}
          className={classes.textInput2}
        />
      </span>

      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          2. {ti('IQ_6_2')}
        </Typography>
        <FormControlLabel
          className={values.IQ_6_2_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_2_A" checked={values.IQ_6_2_A} onChange={handleCheckBox} />}
          label={ti('IQ_6_2_A')}
        />
        <FormControlLabel
          className={values.IQ_6_2_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_2_B" checked={values.IQ_6_2_B} onChange={handleCheckBox} />}
          label={ti('IQ_6_2_B')}
        />
        <FormControlLabel
          className={values.IQ_6_2_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_2_C" checked={values.IQ_6_2_C} onChange={handleCheckBox} />}
          label={ti('IQ_6_2_C')}
        />
        <FormControlLabel
          className={values.IQ_6_2_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_2_D" checked={values.IQ_6_2_D} onChange={handleCheckBox} />}
          label={ti('IQ_6_2_D')}
        />
      </span>

      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          3. {ti('IQ_6_3')}
        </Typography>
        <FormControlLabel
          className={values.IQ_6_3_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_3_A" checked={values.IQ_6_3_A} onChange={handleCheckBox} />}
          label={ti('IQ_6_3_A')}
        />
        <FormControlLabel
          className={values.IQ_6_3_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_3_B" checked={values.IQ_6_3_B} onChange={handleCheckBox} />}
          label={ti('IQ_6_3_B')}
        />
        <FormControlLabel
          className={values.IQ_6_3_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_3_C" checked={values.IQ_6_3_C} onChange={handleCheckBox} />}
          label={ti('IQ_6_3_C')}
        />
        <FormControlLabel
          className={values.IQ_6_3_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_3_D" checked={values.IQ_6_3_D} onChange={handleCheckBox} />}
          label={ti('IQ_6_3_D')}
        />
        <TextField
          label={t('SPECIFY')}
          error={values.IQ_6_3_D && !values.IQ_6_3_D ? true : false}
          id={'IQ_6_3_D_input'}
          disabled={!values.IQ_6_3_D}
          onChange={handleCheckBox}
          value={values.IQ_6_3_D_input}
          className={classes.textInput2}
        />
      </span>

      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          4. {ti('IQ_6_4')}
        </Typography>
        <FormControlLabel
          className={values.IQ_6_4_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_4_A" checked={values.IQ_6_4_A} onChange={handleCheckBox} />}
          label={ti('IQ_6_4_A')}
        />
        <FormControlLabel
          className={values.IQ_6_4_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_4_B" checked={values.IQ_6_4_B} onChange={handleCheckBox} />}
          label={ti('IQ_6_4_B')}
        />
        <FormControlLabel
          className={values.IQ_6_4_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_4_C" checked={values.IQ_6_4_C} onChange={handleCheckBox} />}
          label={ti('IQ_6_4_C')}
        />
        <TextField
          label={t('SPECIFY')}
          error={values.IQ_6_4_C && !values.IQ_6_4_C ? true : false}
          id={'IQ_6_4_C_input'}
          disabled={!values.IQ_6_4_C}
          onChange={handleCheckBox}
          value={values.IQ_6_4_C_input}
          className={classes.textInput2}
        />
      </span>

      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          5. {ti('IQ_6_5')}
        </Typography>
        <Typography variant="inherit" className={classes.questionNote}>
          * {ti('IQ_6_5_note1')}
        </Typography>
        <Typography variant="inherit" className={classes.questionNote}>
          * {ti('IQ_6_5_note2')}
        </Typography>
        <TextField
          label={t('NAME')}
          id={'IQ_6_5_A_input1'}
          fullWidth
          onChange={handleCheckBox}
          value={values.IQ_6_5_A_input1}
          className={classes.textInput2}
        />
        <TextField
          label={t('BIRTH_DATE')}
          id={'IQ_6_5_A_input2'}
          fullWidth
          type='date' InputLabelProps={{ shrink: true }}
          onChange={handleCheckBox}
          value={values.IQ_6_5_A_input2}
          className={classes.textInput2}
        />
        <TextField
          label={t('PERMANENT_OR_OTHER_RESIDENCE')}
          id={'IQ_6_5_A_input3'}
          fullWidth
          onChange={handleCheckBox}
          value={values.IQ_6_5_A_input3}
          className={classes.textInput2}
        />
        <TextField
          label={t('COUNTRY_OF_ORIGIN')}
          id={'IQ_6_5_A_input4'}
          fullWidth
          onChange={handleCheckBox}
          value={values.IQ_6_5_A_input4}
          className={classes.textInput2}
        />
        <TextField
          label={t('TAX_RESIDENCY')}
          id={'IQ_6_5_A_input5'}
          fullWidth
          onChange={handleCheckBox}
          value={values.IQ_6_5_A_input5}
          className={classes.textInput2}
        />
        <FormControlLabel
          className={values.IQ_6_5_A ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_5_A" checked={values.IQ_6_5_A} onChange={handleCheckBox} />}
          label={ti('IQ_6_5_A')}
        />
        <FormControlLabel
          className={values.IQ_6_5_B ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_5_B" checked={values.IQ_6_5_B} onChange={handleCheckBox} />}
          label={ti('IQ_6_5_B')}
        />
        <FormControlLabel
          className={values.IQ_6_5_C ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_5_C" checked={values.IQ_6_5_C} onChange={handleCheckBox} />}
          label={ti('IQ_6_5_C')}
        />
        <FormControlLabel
          className={values.IQ_6_5_D ? classNames(classes.question, classes.active) : classes.question}
          control={<Checkbox id="IQ_6_5_D" checked={values.IQ_6_5_D} onChange={handleCheckBox} />}
          label={ti('IQ_6_5_D')}
        />
        <TextField
          label={t('SPECIFY')}
          error={values.IQ_6_5_D && !values.IQ_6_5_D ? true : false}
          id={'IQ_6_5_D_input'}
          disabled={!values.IQ_6_5_D}
          onChange={handleCheckBox}
          value={values.IQ_6_5_D_input}
          className={classes.textInput2}
        />
      </span>

      <span>
        <Typography variant="inherit" className={classes.questionTitle}>
          6. {ti('IQ_6_6')}
        </Typography>
        <Typography variant="inherit" className={classes.questionNote}>
          * {ti('IQ_6_6_note1')}
        </Typography>
        <Typography variant="inherit" className={classes.questionNote}>
          * {ti('IQ_6_6_note2')}
        </Typography>
        <TextField
          label={t('NAME')}
          id={'IQ_6_6_A_input1'}
          fullWidth
          onChange={handleCheckBox}
          value={values.IQ_6_6_A_input1}
          className={classes.textInput2}
        />
        <TextField
          label={t('BIRTH_DATE')}
          id={'IQ_6_6_A_input2'}
          fullWidth
          type='date' InputLabelProps={{ shrink: true }}
          onChange={handleCheckBox}
          value={values.IQ_6_6_A_input2}
          className={classes.textInput2}
        />
        <TextField
          label={t('PERMANENT_OR_OTHER_RESIDENCE')}
          id={'IQ_6_6_A_input3'}
          fullWidth
          onChange={handleCheckBox}
          value={values.IQ_6_6_A_input3}
          className={classes.textInput2}
        />
        <TextField
          label={t('TAX_RESIDENCY')}
          id={'IQ_6_6_A_input4'}
          fullWidth
          onChange={handleCheckBox}
          value={values.IQ_6_6_A_input4}
          className={classes.textInput2}
        />
      </span>
    </>
  )

  const iq_6 = (
    <>
      <Typography variant="inherit" className={classes.skillsTitle}>
        {ti('IQ_5_1')}
      </Typography>
      <Typography variant="inherit" className={classes.skillsSubtitle}>
        {ti('IQ_5_1_h1')}
      </Typography>
      <div className={classes.resultsWrapper}>
        <span className={classes.resultName}>
          <Typography variant="inherit" className={classes.resultNumber}>
            1
          </Typography>
          <Typography variant="inherit">{ti('IQ_5_1_a')}</Typography>
        </span>
        <Typography variant="inherit">{ti(investmentResult.category)}</Typography>

        <span className={classes.resultName}>
          <Typography variant="inherit" className={classes.resultNumber}>
            2
          </Typography>
          <Typography variant="inherit">{ti('IQ_5_2_a')}</Typography>
        </span>
        <Typography variant="inherit">{ti(investmentResult.skills)}</Typography>

        <span className={classes.resultName}>
          <Typography variant="inherit" className={classes.resultNumber}>
            3
          </Typography>
          <Typography variant="inherit">{ti('IQ_5_3_a')}</Typography>
        </span>
        <Typography variant="inherit">{ti(investmentResult.horizonte)}</Typography>

        <span className={classes.resultName}>
          <Typography variant="inherit" className={classes.resultNumber}>
            4
          </Typography>
          <Typography variant="inherit">{ti('IQ_5_4_a')}</Typography>
        </span>
        <Typography variant="inherit">{ti(investmentResult.investmentGoal)}</Typography>

        <span className={classes.resultName}>
          <Typography variant="inherit" className={classes.resultNumber}>
            5
          </Typography>
          <Typography variant="inherit">{ti('IQ_5_5_a')}</Typography>
        </span>
        <Typography variant="inherit">{ti(investmentResult.lossAbility)}</Typography>

        <span className={classes.resultName}>
          <Typography variant="inherit" className={classes.resultNumber}>
            6
          </Typography>
          <Typography variant="inherit">{ti('IQ_5_6_a')}</Typography>
        </span>
        <Typography variant="inherit">{ti(investmentResult.risk)}</Typography>

        <span className={classes.resultName}>
          <Typography variant="inherit" className={classes.resultNumber}>
            7
          </Typography>
          <Typography variant="inherit">{ti('IQ_5_7_a')}</Typography>
        </span>
        <Typography variant="inherit">{ti(investmentResult.esg)}</Typography>
      </div>
      <Typography variant="inherit" className={classes.skillsTitle}>
        {ti('IQ_5_8')}
      </Typography>
      <Typography variant="inherit" className={classes.skillsSubtitle}>
        {t('OVERVIEW_FOLLOWED_STATS')}
      </Typography>
      <Typography variant="inherit">
        {`${capitalizeFirstLetter(ti('IQ_5_8_a'))} ${investmentResult.totalPoint}`}
      </Typography>
      <Typography variant="inherit">
        {`${capitalizeFirstLetter(ti('IQ_5_8_b'))} ${investmentResult.skillsPoint}`}
      </Typography>
      <Typography variant="inherit">
        {`${capitalizeFirstLetter(ti('IQ_5_8_c'))} ${investmentResult.experiencesPoint}`}
      </Typography>
      <Typography variant="inherit">
        {`${capitalizeFirstLetter(ti('IQ_5_8_d'))} ${investmentResult.financialGround}`}
      </Typography>
      <Typography variant="inherit">
        {`${capitalizeFirstLetter(ti('IQ_5_8_e'))} ${investmentResult.financialGoals}`}
      </Typography>
      <Typography variant="inherit" fontWeight={600}>
        {`${ti('IQ_5_9')} - ${ti(investmentResult.total)}`}
      </Typography>
    </>
  )

  const handleScroll = (event: any) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target
    setShowFadeout(scrollTop + clientHeight < scrollHeight)
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialog }}
      >
        <div className={classes.clientInformationDialogHeader}>
          <Typography variant="h5" fontWeight={600}>
            {headerText[step.step]}
          </Typography>
        </div>
        <DialogContent onScroll={handleScroll}>
          {step.step === 0 && (
            <>
              <Typography variant="inherit">{ti('IQ_INFO')}</Typography>
            </>
          )}
          {step.step === 1 && iq_1}
          {step.step === 2 && iq_2}
          {step.step === 3 && iq_3}
          {step.step === 4 && iq_4}
          {step.step === 5 && iq_5}
          {step.step === 6 && iq_6}
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <div className={classes.circleWrapper}>
            <div className={step.step === 0 ? classNames(classes.circle, classes.activeCircle) : classes.circle} />
            <div className={step.step === 1 ? classNames(classes.circle, classes.activeCircle) : classes.circle} />
            <div className={step.step === 2 ? classNames(classes.circle, classes.activeCircle) : classes.circle} />
            <div className={step.step === 3 ? classNames(classes.circle, classes.activeCircle) : classes.circle} />
            <div className={step.step === 4 ? classNames(classes.circle, classes.activeCircle) : classes.circle} />
            <div className={step.step === 5 ? classNames(classes.circle, classes.activeCircle) : classes.circle} />
          </div>
          <span className={classes.buttons}>
            <Button
              className={classNames(classes.actionButtons, classes.backButton)}
              size="large"
              variant="text"
              color="primary"
              onClick={handleLeftButton}
              disabled={loading}
            >
              {step.step === 0 && <> {t('CANCEL')} </>}
              {step.step > 0 && <> {t('BACK')} </>}
            </Button>
            <Button
              className={classes.actionButtons}
              size="large"
              variant="contained"
              color="primary"
              onClick={handleRightButton}
              disabled={loading}
            >
              {step.step >= 0 && step.step < 6 && <> {t('CONTINUE')} </>}
              {step.step === 6 && <> {t('SAVE')} </>}
            </Button>
          </span>
        </DialogActions>
      </Dialog>

      <MessageAlert
        alertOpen={messageOpen}
        alertText={message}
        onOk={handleCloseMessage}
        buttonText="Vrátit se k vyplňování"
      />
    </div>
  )
}
