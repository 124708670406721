import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useOrderTableRowColor } from '../../hooks/useOrderTableRowColor'
import { useZebraStyle } from '../../hooks/useZebraStyle'
import { useTranslation } from 'react-i18next'
import { Client } from '../../redux/reducers/clients'
import { isClientUser } from '../../services/keycloak.service'
import { useInvestmentApi } from '../../hooks/useInvestmentApi'
import { useSelector } from '../../redux/hooks/useSelector'
import { Investment, IqValues } from '../../redux/reducers/investment'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { format } from 'date-fns'
import { InvestmentDialog } from '../dialogs/InvestmentDialog'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import { INVESTMENT_DIALOG_ID } from '../../constants/dialogConstants'
import CachedIcon from '@mui/icons-material/Cached'
import Button from '@mui/material/Button'
import { newThemeObject } from '../../newTheme'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  tableHead: {
    ...newThemeObject.table.tableHead,
  },
  tableHeadChildrenText: {
    ...newThemeObject.table.tableHeadChildrenText,
  },
  tableHeadChildren: {
    ...newThemeObject.table.tableHeadChildren,
  },
  tableHeadChildrenFirst: {
    ...newThemeObject.table.tableHeadChildrenFirst,
  },
  tableHeadChildrenLast: {
    ...newThemeObject.table.tableHeadChildrenLast,
  },
  tableContent: {
    fontSize: 30,
    padding: '0px 16px',
  },
  tableContainer: {
    ...newThemeObject.table.tableContainer,
  },
  tableContentText: {
    ...newThemeObject.table.tableContentText,
  },
  tableBody: {
    ...newThemeObject.table.tableBody,
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    borderRadius: newThemeObject.roundness.basic,
  },
}))

interface Props {
  client: Client
  setReloadClient: any
}

export const IvestmentUpdate: React.FC<Props> = ({ client, setReloadClient }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { getInvestments } = useInvestmentApi()
  const investments = useSelector(state => state.investments)

  const dispatch = useDispatch()

  useEffect(() => {
    if (client) getInvestments(client.clientId)
  }, [client])

  const handleDownLoad = (row: Investment) => () => {
    const linkSource = `data:application/pdf;base64,${row.pdfContent}`
    const downloadLink = document.createElement('a')
    const fileName = 'id_' + client.surname + '_' + row.signatureDate + '.pdf'
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  const handleCloseDialog = () => {
    getInvestments(client.clientId)
    setReloadClient(true)
  }

  const [existValues, setExistValues] = useState<IqValues>()
  useEffect(() => {
    if (investments && investments.investments && investments.investments[0]) {
      let tmp = investments.investments[0].iqValues
      setExistValues(tmp)
    }
  }, [investments])

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
        <span className={classes.topBar}>
          <p>{t('INVESTMENT_HISTORY')}</p>
          {isClientUser() && (
            <span>
              <Button
                onClick={() => {
                  dispatch(openDialog(INVESTMENT_DIALOG_ID))
                }}
                className={classes.button}
                title={t('UPDATE_QUESTIONNAIRE')}
                startIcon={<CachedIcon />}
                variant="contained"
                disableElevation
              >
                {t('UPDATE_QUESTIONNAIRE')}
              </Button>
            </span>
          )}
        </span>

        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell className={classNames([classes.tableHeadChildren, classes.tableHeadChildrenFirst])}>
                <p className={classes.tableHeadChildrenText}>{t('SIGNATURE_DATE')}</p>
              </TableCell>
              <TableCell className={classes.tableHeadChildren}>
                <p className={classes.tableHeadChildrenText}>{t('VALIDITY_TO')}</p>
              </TableCell>
              <TableCell className={classNames([classes.tableHeadChildren, classes.tableHeadChildrenLast])}>
                <p className={classes.tableHeadChildrenText}>{t('PDF')}</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {investments.investments.map((item: Investment) => (
              <TableRow className={classes.tableContent} key={investments.investments.id}>
                <TableCell className={classes.tableContent}>
                  <p className={classes.tableContentText}>{format(new Date(item.signatureDate), 'dd/MM/yyyy')}</p>
                </TableCell>
                <TableCell className={classes.tableContent}>
                  <p className={classes.tableContentText}>{format(new Date(item.validTo), 'dd/MM/yyyy')}</p>
                </TableCell>
                <TableCell className={classes.tableContent}>
                  {' '}
                  {item.pdfContent && <DownloadForOfflineIcon onClick={handleDownLoad(item)} />}{' '}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <InvestmentDialog client={client} handleCloseDialog={handleCloseDialog} existState={existValues} />
    </>
  )
}
