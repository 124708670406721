import { newOrder } from '../../constants/initialStates'
import { ReduxAction } from '../../types/common'
import {
  GET_ORDER,
  GET_ORDER_TABLE_ROWS,
  GET_ORDERS,
  GET_ORDERS_BY_STATE_COUNT,
  SEE_ORDER,
  UPDATE_ORDER,
} from '../actionTypes'

export interface OrdersByStateCount {
  count: number
  state: string
}

export interface OrderTableRow {
  amount: number
  clientFirstname: string
  clientId: number
  clientSurname: string
  currency: string
  direction: string
  orderId: number
  orderIdent: string
  securityId: number
  securityName: string
  instrumentType: string
  state: string
  sendToFix: boolean
  orderCreationDate: Date
  validityType: string
  validityDate: string
  price: number
  quantity: number
  isin: string
  symbol: string
  ticker: string
  orderMarketType: string
  assetClass: string
}
interface OrderState {
  orders: Order[]
  ordersByStateCount: OrdersByStateCount[]
  orderTableRows: OrderTableRow[]
  order: Order
  orderSse: any
}

const INITIAL_STATE = {
  orders: [],
  ordersByStateCount: [],
  orderTableRows: [],
  order: newOrder,
  orderSse: {},
}
export interface Order {
  amount: number | null
  attachments: number | null
  cpty: {
    brokerName: string
    marketName: string
    name: string
  }
  currency: string | null
  direction: string
  orderId: number | null
  orderIdent: number | null
  portfolio: {
    currency: string | null
    name: string | null
    portfolioId: Number | null
    firstIndicatorDate: null
    lastIndicatorDate: null
  }
  price: number | null
  quantity: number | null
  filledQuantity: number | null
  securityId: number | null
  state: string | null
  type: string
  validityDate: Date | null
  validityType: string
  marketType: string
  orderCreationDate: string | Date | null
  acceptationDate: string | Date | null
  actions: OrderAction[]
  omsId: number | null
  orderComment: string | null
  sendToFix: boolean | null
  fee: number | null
  errorDescription: string | null
}

export interface OrderAction {
  orderActionId: number | null
  extenalId: string | null
  code: string | null
  fix: string | null
  price: number | null
  amount: number | null
  quantity: number | null
  fee: number | null
  currency: string | null
  market: string | null
  date: Date | null
}

export const orders = (state: OrderState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload as Order[],
      }
    case GET_ORDERS_BY_STATE_COUNT:
      return {
        ...state,
        ordersByStateCount: action.payload as OrdersByStateCount[],
      }
    case GET_ORDER_TABLE_ROWS:
      return {
        ...state,
        orderTableRows: action.payload as OrderTableRow[],
      }
    case GET_ORDER:
      return {
        ...state,
        order: action.payload as Order,
      }
    case UPDATE_ORDER:
      return {
        ...state,
        order: action.payload as Order,
      }
    case SEE_ORDER:
      return {
        ...state,
        orderSse: action.payload,
      }
    default:
      return state
  }
}
