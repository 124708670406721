import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect } from 'react'
import { ContentTitle } from '../components/common/ContentTitle'
import { ActionAlert } from '../components/dialogs/ActionAlert'
import { ActionFailedAlert } from '../components/dialogs/ActionFailedAlert'
import { FileImportDialog } from '../components/dialogs/FileImportDialog'
import { BasicLayout } from '../components/layouts/BasicLayout'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import { Pages } from '../router/router'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import QuizIcon from '@mui/icons-material/Quiz'
import { useParams } from 'react-router'
import { useSelector } from '../redux/hooks/useSelector'
import { useClientApi } from '../hooks/useClientApi'
import manS from '../assets/manS.jpg'
import NumberFormat from 'react-number-format'
import { TransparentIconButton } from '../components/common/TransparentIconButton'
import AppsIcon from '@mui/icons-material/Apps'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { useHistory } from 'react-router-dom'
import { InvestmentDisplay } from '../components/Investment/InvestmentDisplay'
import { useState } from 'react'
import { getClientIdFromUser, isClientUser } from '../services/keycloak.service'
import { useTranslation } from 'react-i18next'
import { NewLayout } from '../components/newLayout/NewLayout'

const useStyles = makeStyles(theme => ({
  clientEssentials: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  manIcon: {
    marginTop: theme.spacing(1),
  },
  clientPersonalInfoColumn: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
  },
  test: {
    background: 'red',
    width: '200px',
    position: 'absolute',
    top: '20px',
    left: '-50px',
    opacity: '0.95',
    textAlign: 'center',
    lineHeight: '25px',
    fontSize: '16px',
    letterSpacing: '1px',
    color: '#f0f0f0',
    transform: 'rotate(-45deg)',
    paddingRight: '18px',
  },
}))

export const InvestmentQuestionnaire = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { clientId } = useParams<{ clientId: string }>()
  const getClientId = () => {
    if (isClientUser()) {
      return getClientIdFromUser()
    } else {
      return clientId
    }
  }

  const clientId2 = getClientId()
  const client = useSelector(state => state.clients.client)
  const { getClient } = useClientApi()
  const [reloadClient, setReloadClient] = useState(false)

  useEffect(() => {
    getClient(Number(clientId2))
  }, [])

  useEffect(() => {
    if (reloadClient) getClient(Number(clientId2))
  }, [reloadClient])

  return (
    <NewLayout pageName={t('INVESTMENT_QUESTIONNAIRE')}>
      <InvestmentDisplay client={client} setReloadClient={setReloadClient} />

      <FileImportDialog />
      <ActionAlert />
      <ActionFailedAlert handleClose={()=>{}}/>
    </NewLayout>
  )
}
