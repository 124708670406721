import { Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Client } from '../../redux/reducers/clients'
import { IvestmentTable } from './InvestmentTable'
import { IvestmentUpdate } from './InvestmentUpdate'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  orderTable: {
    height: '100%',
    overflow: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '5fr 4fr',
    },
  },

  orderDisplayTable: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(6),
    flexGrow: 1,
  },
  header: {
    paddingTop: '20px',
  },
}))

interface Props {
  client: Client
  setReloadClient: any
}

export const InvestmentDisplay: React.FC<Props> = ({ client, setReloadClient }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.orderTable}>
      <span>
        <IvestmentUpdate client={client} setReloadClient={setReloadClient} />
      </span>
      <span>
        <IvestmentTable client={client} />
      </span>
    </div>
  )
}
