import { combineReducers } from 'redux'
import { auth } from './auth'
import { clients } from './clients'
import { dialogs } from './dialogs'
import { filters } from './filters'
import { detailPortfolioState } from './portfolio'
import { securities } from './securities'
import { orders } from './orders'
import { currency } from './currency'
import { market } from './market'
import { useractions } from './useractions'
import { roles } from './role'
import { investments } from './investment'
import { loading } from './loading'
import { labelState } from './label'
import { noteState } from './note'

export const rootReducer = combineReducers({
  auth,
  clients,
  dialogs,
  filters,
  detailPortfolioState,
  securities,
  orders,
  currency,
  useractions,
  loading,
  roles,
  investments,
  market,
  labelState,
  noteState
})

export type RootState = ReturnType<typeof rootReducer>
