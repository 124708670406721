import { useTranslation } from 'react-i18next'
import { newThemeObject } from '../../newTheme'
import { makeStyles } from '@mui/styles'
import { formatNumber } from '../../utils/numbersFormatter'

const useStyles = makeStyles(theme => ({
  title: {
    color: newThemeObject.palette.main.primary,
    fontWeight: 600,
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
  subtitle: {
    color: newThemeObject.palette.text.gray,
    fontWeight: 500,
    fontSize: '0.8rem',
    margin: 0,
    padding: 0,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
  },
  label: {
    justifySelf: 'left',
    color: newThemeObject.palette.text.gray,
    fontWeight: 500,
    fontSize: '0.8rem',
  },
  number: {
    justifySelf: 'right',
    fontWeight: 500,
    fontSize: '0.8rem',
  },
  totalWrapper: {
    borderRadius: newThemeObject.roundness.basic,
    backgroundColor: newThemeObject.palette.main.dark,
    alignItems: 'center',
    display: 'flex',
    minWidth: '100%',
    padding: newThemeObject.padding.smallWrapper,
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  totalText: {
    color: '#fff',
    fontWeight: 500,
    fontSize: '0.8rem',
    margin: 0,
  },
  totalNumber: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '1.2rem',
    margin: 0,
  },
  groupWrapper: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, 1fr)',
    gap: theme.spacing(1),
  },
  oneYearWrapper: {
    borderRadius: newThemeObject.roundness.basic,
    backgroundColor: newThemeObject.palette.background.lightGray,
    alignItems: 'center',
    display: 'flex',
    minWidth: '100%',
    padding: newThemeObject.padding.smallWrapper,
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  oneYearText: {
    fontWeight: 500,
    fontSize: '0.8rem',
    margin: 0,
    maxWidth: '50%',
  },
  oneYearNumber: {
    justifySelf: 'right',
    color: newThemeObject.palette.text.gray,
    fontWeight: 600,
    fontSize: '1.2rem',
    margin: 0,
  },
}))

interface FixedIncomeStatsProps {
  title: string
  HSNumber: number
  ABNumber: number
  TNumber: number
  OYNumber: number
  isLabel: boolean
}

const FixedIncomeStats: React.FC<FixedIncomeStatsProps> = ({
  title,
  HSNumber,
  ABNumber,
  TNumber,
  OYNumber,
  isLabel
}: FixedIncomeStatsProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <span className={classes.titleWrapper}>
        <p className={classes.title}>{t('FIXING_INCOME')}</p>
        <p className={classes.subtitle}>{`- ${title}`}</p>
      </span>
      {!isLabel &&
        <div className={classes.content}>
          <p className={classes.label}>{t('HOLDIN_SECURITIES')}</p>
          <p className={classes.number}>{formatNumber(HSNumber)} CZK</p>
          <p className={classes.label}>{t('ACCOUNT_BALANCE_IN_CZK')}</p>
          <p className={classes.number}>{formatNumber(ABNumber)} CZK</p>
        </div>
      }
      {isLabel &&
        <div className={classes.content}>
          <p className={classes.label}></p>
          <p className={classes.number}></p>
        </div>
      }
      <div className={classes.groupWrapper}>
        <div className={classes.totalWrapper}>
          <p className={classes.totalText}>{t('MARKET_PORTFOLIO_AMOUNT')}</p>
          <p className={classes.totalNumber}>{formatNumber(TNumber)} CZK</p>
        </div>
        <div className={classes.oneYearWrapper}>
          <p className={classes.oneYearText}>{t('EXPECTED_PORTFOLIO_AMOUNT')}</p>
          <p className={classes.oneYearNumber}>{formatNumber(OYNumber-TNumber)} CZK</p>
        </div>
      </div>
    </>
  )
}

export default FixedIncomeStats
